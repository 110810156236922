import { AggregationTypes } from "pages/Reports/redux/reducers/sweetStateHooks/useAggregated";
import { REPORTS_FULL_PATHS } from "pages/Reports/utils";
import { pickReportToValidate } from "pages/Reports/utils/validateParams/utils/pickMetricsToValidate";
import { validateMultipleProperties } from "pages/Reports/utils/validateParams/utils/validateMultipleProperties";
import { APP_PATHS } from "utils";
import {
  getAvailablePeriods,
  getDefaultPeriod
} from "utils/constants/chartPeriods";
import {
  DYNAMICS_COMBINED_CHART_DATA_TYPES,
  HOURS_FIRST_CHART_DATA_TYPES,
  HOURS_SECOND_CHART_DATA_TYPES,
  LOCATION_FIRST_CHART_DATA_TYPES,
  LOCATION_SECOND_CHART_DATA_TYPES,
  LOGISTICS_COMBINED_CHART_DATA_TYPES,
  LOYALTY_ALL_CHART_DATA_TYPES,
  MULTIPACK_CHART_DATA_TYPES,
  RECEIPTS_COMBINED_CHART_DATA_TYPES,
  SEGMENTS_COMBINED_CHART_DATA_TYPES,
  SELL_COMBINED_CHART_DATA_TYPES,
  SHARES_COMBINED_CHART_DATA_TYPES
} from "utils/constants/dataTypes";
import {
  DYNAMICS_AVAILABLE_SUMMARY,
  DYNAMICS_DEFAULT_SUMMARY,
  LOCATION_AVAILABLE_SUMMARY,
  LOCATION_DEFAULT_SUMMARY,
  LOYALTY_AVAILABLE_SUMMARY,
  LOYALTY_DEFAULT_SUMMARY,
  MULTIPACK_AVAILABLE_SUB_SUMMARY,
  MULTIPACK_AVAILABLE_SUMMARY,
  MULTIPACK_DEFAULT_SUB_SUMMARY,
  MULTIPACK_DEFAULT_SUMMARY,
  RECEIPTS_AVAILABLE_SUMMARY,
  RECEIPTS_DEFAULT_SUMMARY,
  REGULAR_AVAILABLE_SUB_SUMMARY,
  REGULAR_DEFAULT_SUB_SUMMARY,
  SELL_AVAILABLE_SUMMARY,
  SELL_DEFAULT_SUMMARY,
  SHARES_AVAILABLE_SUMMARY,
  SHARES_DEFAULT_SUMMARY
} from "utils/constants/summaryTabs";
import { DEFAULT_DATA_TYPES, QP } from "utils/defaultQueryParams";

const {
  SELL_PATH,
  LOCATION_PATH,
  SHARES_PATH,
  DYNAMICS_PATH,
  RECEIPTS_PATH,
  HOURS_PATH,
  SEGMENTS_PATH,
  LOYALTY_PATH,
  MULTIPACK_PATH
} = REPORTS_FULL_PATHS;

export type MetricValidationRequiredInfo = {
  key: string;
  availableValues: string[];
  defaultValue: string | string[];
};

export const getMetricsArray = (search: string) => ({
  sell: [
    {
      key: QP.CHART_DATA_TYPE_FIRST,
      availableValues: SELL_COMBINED_CHART_DATA_TYPES,
      defaultValue: DEFAULT_DATA_TYPES.sell[0]
    },
    {
      key: QP.CHART_DATA_TYPE_SECOND,
      availableValues: SELL_COMBINED_CHART_DATA_TYPES,
      defaultValue: DEFAULT_DATA_TYPES.sell[1]
    },
    {
      key: QP.CHART_PERIOD,
      availableValues: getAvailablePeriods(SELL_PATH, search),
      defaultValue: getDefaultPeriod(SELL_PATH, search)
    },
    {
      key: QP.SELL_SUMMARY_TYPE,
      availableValues: SELL_AVAILABLE_SUMMARY,
      defaultValue: SELL_DEFAULT_SUMMARY
    },
    {
      key: QP.SUMMARY_SUB_TYPE,
      availableValues: REGULAR_AVAILABLE_SUB_SUMMARY,
      defaultValue: REGULAR_DEFAULT_SUB_SUMMARY
    }
  ],
  location: [
    {
      key: QP.CHART_DATA_TYPE_FIRST,
      availableValues: LOCATION_FIRST_CHART_DATA_TYPES,
      defaultValue: DEFAULT_DATA_TYPES.location[0]
    },
    {
      key: QP.CHART_DATA_TYPE_SECOND,
      availableValues: LOCATION_SECOND_CHART_DATA_TYPES,
      defaultValue: DEFAULT_DATA_TYPES.location[1]
    },
    {
      key: QP.CHART_PERIOD,
      availableValues: getAvailablePeriods(LOCATION_PATH, search),
      defaultValue: getDefaultPeriod(LOCATION_PATH, search)
    },
    {
      key: QP.LOCATION_SUMMARY_TYPE,
      availableValues: LOCATION_AVAILABLE_SUMMARY,
      defaultValue: LOCATION_DEFAULT_SUMMARY
    },
    {
      key: QP.SUMMARY_SUB_TYPE,
      availableValues: REGULAR_AVAILABLE_SUB_SUMMARY,
      defaultValue: REGULAR_DEFAULT_SUB_SUMMARY
    }
  ],
  shares: [
    {
      key: QP.CHART_DATA_TYPE_FIRST,
      availableValues: SHARES_COMBINED_CHART_DATA_TYPES,
      defaultValue: DEFAULT_DATA_TYPES.shares[0]
    },
    {
      key: QP.CHART_DATA_TYPE_SECOND,
      availableValues: SHARES_COMBINED_CHART_DATA_TYPES,
      defaultValue: DEFAULT_DATA_TYPES.shares[1]
    },
    {
      key: QP.CHART_PERIOD,
      availableValues: getAvailablePeriods(SHARES_PATH, search),
      defaultValue: getDefaultPeriod(SHARES_PATH, search)
    },
    {
      key: QP.SHARES_SUMMARY_TYPE,
      availableValues: SHARES_AVAILABLE_SUMMARY,
      defaultValue: SHARES_DEFAULT_SUMMARY
    },
    {
      key: QP.SUMMARY_SUB_TYPE,
      availableValues: REGULAR_AVAILABLE_SUB_SUMMARY,
      defaultValue: REGULAR_DEFAULT_SUB_SUMMARY
    }
  ],
  dynamics: [
    {
      key: QP.CHART_DATA_TYPE_FIRST,
      availableValues: DYNAMICS_COMBINED_CHART_DATA_TYPES,
      defaultValue: DEFAULT_DATA_TYPES.dynamics[0]
    },
    {
      key: QP.CHART_DATA_TYPE_SECOND,
      availableValues: DYNAMICS_COMBINED_CHART_DATA_TYPES,
      defaultValue: DEFAULT_DATA_TYPES.dynamics[1]
    },
    {
      key: QP.CHART_PERIOD,
      availableValues: getAvailablePeriods(DYNAMICS_PATH, search),
      defaultValue: getDefaultPeriod(DYNAMICS_PATH, search)
    },
    {
      key: QP.DYNAMICS_SUMMARY_TYPE,
      availableValues: DYNAMICS_AVAILABLE_SUMMARY,
      defaultValue: DYNAMICS_DEFAULT_SUMMARY
    },
    {
      key: QP.SUMMARY_SUB_TYPE,
      availableValues: REGULAR_AVAILABLE_SUB_SUMMARY,
      defaultValue: REGULAR_DEFAULT_SUB_SUMMARY
    }
  ],
  receipts: [
    {
      key: QP.CHART_DATA_TYPE_FIRST,
      availableValues: RECEIPTS_COMBINED_CHART_DATA_TYPES,
      defaultValue: DEFAULT_DATA_TYPES.receipts[0]
    },
    {
      key: QP.CHART_DATA_TYPE_SECOND,
      availableValues: RECEIPTS_COMBINED_CHART_DATA_TYPES,
      defaultValue: DEFAULT_DATA_TYPES.receipts[1]
    },
    {
      key: QP.CHART_PERIOD,
      availableValues: getAvailablePeriods(RECEIPTS_PATH, search),
      defaultValue: getDefaultPeriod(RECEIPTS_PATH, search)
    },
    {
      key: QP.RECEIPTS_SUMMARY_TYPE,
      availableValues: RECEIPTS_AVAILABLE_SUMMARY,
      defaultValue: RECEIPTS_DEFAULT_SUMMARY
    },
    {
      key: QP.SUMMARY_SUB_TYPE,
      availableValues: REGULAR_AVAILABLE_SUB_SUMMARY,
      defaultValue: REGULAR_DEFAULT_SUB_SUMMARY
    }
  ],
  segments: [
    {
      key: QP.CHART_DATA_TYPE_FIRST,
      availableValues: SEGMENTS_COMBINED_CHART_DATA_TYPES,
      defaultValue: DEFAULT_DATA_TYPES.segments[0]
    },
    {
      key: QP.CHART_DATA_TYPE_SECOND,
      availableValues: SEGMENTS_COMBINED_CHART_DATA_TYPES,
      defaultValue: DEFAULT_DATA_TYPES.segments[1]
    },
    {
      key: QP.CHART_PERIOD,
      availableValues: getAvailablePeriods(SEGMENTS_PATH, search),
      defaultValue: getDefaultPeriod(SEGMENTS_PATH, search)
    },
    {
      key: QP.AGGREGATED,
      availableValues: [AggregationTypes.total, AggregationTypes.false],
      defaultValue: AggregationTypes.false
    }
  ],
  logistics: [
    {
      key: QP.CHART_DATA_TYPE_FIRST,
      availableValues: LOGISTICS_COMBINED_CHART_DATA_TYPES,
      defaultValue: DEFAULT_DATA_TYPES.logistics[0]
    },
    {
      key: QP.CHART_DATA_TYPE_SECOND,
      availableValues: LOGISTICS_COMBINED_CHART_DATA_TYPES,
      defaultValue: DEFAULT_DATA_TYPES.logistics[1]
    },
    {
      key: QP.AGGREGATED,
      availableValues: [AggregationTypes.total, AggregationTypes.false],
      defaultValue: AggregationTypes.false
    }
  ],
  hours: [
    {
      key: QP.CHART_DATA_TYPE_FIRST,
      availableValues: HOURS_FIRST_CHART_DATA_TYPES,
      defaultValue: DEFAULT_DATA_TYPES.hours[0]
    },
    {
      key: QP.CHART_DATA_TYPE_SECOND,
      availableValues: HOURS_SECOND_CHART_DATA_TYPES,
      defaultValue: DEFAULT_DATA_TYPES.hours[1]
    },
    {
      key: QP.CHART_PERIOD,
      availableValues: getAvailablePeriods(HOURS_PATH, search),
      defaultValue: getDefaultPeriod(HOURS_PATH, search)
    },
    {
      key: QP.AGGREGATED,
      availableValues: [AggregationTypes.total, AggregationTypes.false],
      defaultValue: AggregationTypes.false
    }
  ],
  export: [
    {
      key: QP.CHART_PERIOD,
      availableValues: getAvailablePeriods(APP_PATHS.EXPORT, search),
      defaultValue: getDefaultPeriod(APP_PATHS.EXPORT, search)
    },
    {
      key: QP.AGGREGATED,
      availableValues: [AggregationTypes.total, AggregationTypes.false],
      defaultValue: AggregationTypes.false
    }
  ],
  loyalty: [
    {
      key: QP.CHART_DATA_TYPE_FIRST,
      availableValues: LOYALTY_ALL_CHART_DATA_TYPES,
      defaultValue: DEFAULT_DATA_TYPES.loyalty[0]
    },
    {
      key: QP.CHART_DATA_TYPE_SECOND,
      availableValues: LOYALTY_ALL_CHART_DATA_TYPES,
      defaultValue: DEFAULT_DATA_TYPES.loyalty[1]
    },
    {
      key: QP.CHART_PERIOD,
      availableValues: getAvailablePeriods(LOYALTY_PATH, search),
      defaultValue: getDefaultPeriod(LOYALTY_PATH, search)
    },
    {
      key: QP.LOYALTY_SUMMARY_TYPE,
      availableValues: LOYALTY_AVAILABLE_SUMMARY,
      defaultValue: LOYALTY_DEFAULT_SUMMARY
    },
    {
      key: QP.SUMMARY_SUB_TYPE,
      availableValues: REGULAR_AVAILABLE_SUB_SUMMARY,
      defaultValue: REGULAR_DEFAULT_SUB_SUMMARY
    },
    {
      key: QP.AGGREGATED,
      availableValues: [AggregationTypes.total, AggregationTypes.false],
      defaultValue: AggregationTypes.false
    }
  ],
  multipack: [
    {
      key: QP.CHART_DATA_TYPE_FIRST,
      availableValues: MULTIPACK_CHART_DATA_TYPES,
      defaultValue: DEFAULT_DATA_TYPES.multipack[0]
    },
    {
      key: QP.CHART_DATA_TYPE_SECOND,
      availableValues: MULTIPACK_CHART_DATA_TYPES,
      defaultValue: DEFAULT_DATA_TYPES.multipack[1]
    },
    {
      key: QP.CHART_PERIOD,
      availableValues: getAvailablePeriods(MULTIPACK_PATH, search),
      defaultValue: getDefaultPeriod(MULTIPACK_PATH, search)
    },
    {
      key: QP.MULTIPACK_SUMMARY_TYPE,
      availableValues: MULTIPACK_AVAILABLE_SUMMARY,
      defaultValue: MULTIPACK_DEFAULT_SUMMARY
    },
    {
      key: QP.SUMMARY_SUB_TYPE,
      availableValues: MULTIPACK_AVAILABLE_SUB_SUMMARY,
      defaultValue: MULTIPACK_DEFAULT_SUB_SUMMARY
    },
    {
      key: QP.AGGREGATED,
      availableValues: [AggregationTypes.total, AggregationTypes.false],
      defaultValue: AggregationTypes.false
    }
  ]
});

export const validateParamsPerReport = (location: string, search: string) => {
  const keysToChange = pickReportToValidate(location, search);
  return validateMultipleProperties(keysToChange, search);
};
