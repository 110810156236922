import memoize from "memoize-one";
import { createSelector } from "reselect";

import { reportsSelector } from "pages/Reports/redux/selectors/reportsSelectors";
import { reportExportQueryByPeriodSelector } from "store/selectors/routerSelectors";

const receiptsReportSelector = createSelector(
  reportsSelector,
  reports => reports.receipts.report
);

export const receiptsReportLoadingSelector = createSelector(
  receiptsReportSelector,
  report => report.isFetching
);

const receiptsReportsUrlsSelector = createSelector(
  receiptsReportSelector,
  ({ urls }) => urls
);

export const receiptsReportUrlByPeriodSelector = createSelector(
  receiptsReportsUrlsSelector,
  reportExportQueryByPeriodSelector,
  (receiptsReportsUrls, reportExportQueryByPeriod) =>
    memoize(
      (period: string) =>
        receiptsReportsUrls[reportExportQueryByPeriod(period)] || ""
    )
);
