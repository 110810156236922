import memoize from "memoize-one";
import qs from "query-string";
import { createSelector } from "reselect";

import { Section } from "pages/Insights/utils/types";
import {
  EXPORT_DATA_TYPE,
  ExportDataTypeState
} from "pages/Reports/redux/reducers/filters/exportDataTypeReducer";
import { generateReportExportParams } from "pages/Reports/sections/Sell/redux/utils";
import {
  ChartDataTypes,
  PromotionChartDataType
} from "pages/Reports/types/chart";
import {
  SUMMARY_SUB_SWITCH,
  SUMMARY_SWITCH
} from "pages/Reports/utils/constants";
import { AppState } from "store";
import { toArray } from "store/utils";
import {
  BOOL_STRING_VALUES,
  CHART_TYPES,
  LOGISTICS_DATA_TYPES_PARAMS,
  PERIOD_TYPE,
  validatePeriod
} from "utils";
import { QP } from "utils/defaultQueryParams";

const routerSelector = (store: AppState) => store.router;

export const pathnameSelector = createSelector(
  routerSelector,
  router => router.location.pathname
);

export const searchStringSelector = createSelector(
  routerSelector,
  router => router.location.search
);

export const pathSelector = createSelector(
  pathnameSelector,
  searchStringSelector,
  (pathname, search) => pathname + search
);

export const urlParamSelector = createSelector(
  pathnameSelector,
  pathname => pathname.split("/").slice(-1)[0]
);

export const queryParamsSelector = createSelector(
  searchStringSelector,
  search => qs.parse(search, { arrayFormat: "comma" })
);

export const vendorIdQueryParamSelector = createSelector(
  queryParamsSelector,
  queryParams => queryParams[QP.VENDOR_ID] || ""
);

export const chartTypeQueryParamSelector = createSelector(
  queryParamsSelector,
  queryParams => String(queryParams[QP.CHART_TYPE] || CHART_TYPES.LINE)
);

export const categoriesSelector = createSelector(
  queryParamsSelector,
  queryParams => ({
    category1: toArray(queryParams[QP.CATEGORY1]) as string[],
    category2: toArray(queryParams[QP.CATEGORY2]) as string[],
    category3: toArray(queryParams[QP.CATEGORY3]) as string[]
  })
);

export const referenceCategoryAggragetedSelector = createSelector(
  queryParamsSelector,
  queryParams => queryParams[QP.REF_CAT_AGGREGATED] || ""
);

export const referenceBrandAggragetedSelector = createSelector(
  queryParamsSelector,
  queryParams => queryParams[QP.REF_BRAND_AGGREGATED] || ""
);

export const chartSidebarQueryParamsSelector = createSelector(
  queryParamsSelector,
  queryParams => ({
    sidebarSortBy: queryParams[QP.SIDEBAR_SORT_BY] as string,
    sidebarDesc: queryParams[QP.SIDEBAR_DESC] as string,
    sidebarSortType: queryParams[QP.SIDEBAR_SORT_TYPE] as string,
    sidebarSortGroup: queryParams[QP.SIDEBAR_SORT_GROUP] as string,
    sidebarSortEnd: queryParams[QP.SIDEBAR_SORT_END] as string
  })
);

export const isCategorySelector = createSelector(
  categoriesSelector,
  ({ category1, category2, category3 }) =>
    [...category1, ...category2, ...category3].length > 0
);

export const productsQueryParamSelector = createSelector(
  queryParamsSelector,
  queryParams => queryParams[QP.PRODUCT] || ""
);

export const voivodeshipQueryParamSelector = createSelector(
  queryParamsSelector,
  queryParams => queryParams[QP.VOIVODESHIPS] || ""
);

export const countiesQueryParamSelector = createSelector(
  queryParamsSelector,
  queryParams => queryParams[QP.COUNTIES] || ""
);

export const reportTypeSelector = createSelector(
  queryParamsSelector,
  queryParams => (queryParams[QP.REPORT_TYPE] || "").toString()
);

export const sentimentSelector = createSelector(
  queryParamsSelector,
  queryParams => (queryParams[QP.SENTIMENT] || "").toString()
);

export const competitionCompaniesQueryParamSelector = createSelector(
  queryParamsSelector,
  queryParams => queryParams[QP.COMPETING_COMPANIES] || ""
);

export const competitionProductsQueryParamSelector = createSelector(
  queryParamsSelector,
  queryParams => queryParams[QP.COMPETING_MATERIALS] || ""
);

export const delayCompetitionQueryParamSelector = createSelector(
  queryParamsSelector,
  queryParams => String(queryParams[QP.DELAY] || "")
);

export const aggregatedCompetitionQueryParamSelector = createSelector(
  queryParamsSelector,
  queryParams => String(queryParams[QP.AGGREGATED_COMPETITION] || "")
);

export const shopTypesQueryParamsSelector = createSelector(
  queryParamsSelector,
  queryParams => String(queryParams[QP.STORE_TYPES] || "")
);

export const shopTypesMetaQueryParamsSelector = createSelector(
  queryParamsSelector,
  queryParams => String(queryParams[QP.STORE_TYPES_META] || "")
);

export const clientTypeQueryParamSelector = createSelector(
  queryParamsSelector,
  queryParams => String(queryParams[QP.CLIENT] || "")
);

export const chartPeriodSelector = createSelector(
  queryParamsSelector,
  queryParams =>
    validatePeriod(String(queryParams[QP.CHART_PERIOD] || PERIOD_TYPE.DAYS))
);

export const exportDataTypeQueryParamsSelector = createSelector(
  queryParamsSelector,
  queryParams =>
    (queryParams[QP.EXPORT_DATA_TYPE] ||
      EXPORT_DATA_TYPE.GENERAL) as ExportDataTypeState
);

export const tablePeriodSelector = createSelector(
  queryParamsSelector,
  queryParams =>
    validatePeriod(String(queryParams[QP.TABLE_PERIOD] || PERIOD_TYPE.DAYS))
);

export const isReportTableOpenQPSelector = createSelector(
  queryParamsSelector,
  queryParams => queryParams[QP.TABLE_OPEN] || ""
);

export const isReportTableOpenSelector = createSelector(
  isReportTableOpenQPSelector,
  isReportTableOpenQP =>
    isReportTableOpenQP === BOOL_STRING_VALUES.TRUE.toLowerCase()
);

export const firstChartDataTypeSelector = createSelector(
  queryParamsSelector,
  queryParams =>
    String(
      queryParams[QP.CHART_DATA_TYPE_FIRST]
    ).toUpperCase() as ChartDataTypes
);

export const secondChartDataTypeSelector = createSelector(
  queryParamsSelector,
  queryParams =>
    String(
      queryParams[QP.CHART_DATA_TYPE_SECOND] || ""
    ).toUpperCase() as ChartDataTypes
);

export const promotionChartDataTypeSelector = createSelector(
  queryParamsSelector,
  queryParams => String(queryParams[QP.PROMOTIONS_CHART_DATA_TYPE] || "")
);

export const uppercasedPromotionChartDataTypeSelector = createSelector(
  promotionChartDataTypeSelector,
  promotionChartDataType =>
    promotionChartDataType.toUpperCase().split(",") as PromotionChartDataType[]
);

export const uppercasedSellSummaryTabSelector = createSelector(
  queryParamsSelector,
  queryParams =>
    String(
      queryParams[QP.SELL_SUMMARY_TYPE] || SUMMARY_SWITCH.TOTAL
    ).toUpperCase()
);

export const uppercasedLoyaltySummaryTabSelector = createSelector(
  queryParamsSelector,
  queryParams =>
    String(
      queryParams[QP.LOYALTY_SUMMARY_TYPE] || SUMMARY_SWITCH.TOTAL
    ).toUpperCase()
);

export const uppercasedLocationSummaryTabSelector = createSelector(
  queryParamsSelector,
  queryParams =>
    String(
      queryParams[QP.LOCATION_SUMMARY_TYPE] || SUMMARY_SWITCH.TOTAL
    ).toUpperCase()
);

export const uppercasedSharesSummaryTabSelector = createSelector(
  queryParamsSelector,
  queryParams =>
    String(
      queryParams[QP.SHARES_SUMMARY_TYPE] || SUMMARY_SWITCH.VALUE_QUANTITY
    ).toUpperCase()
);

export const uppercasedDynamicsSummaryTabSelector = createSelector(
  queryParamsSelector,
  queryParams =>
    String(
      queryParams[QP.DYNAMICS_SUMMARY_TYPE] || SUMMARY_SWITCH.WORTH
    ).toUpperCase()
);

export const uppercasedReceiptsSummaryTabSelector = createSelector(
  queryParamsSelector,
  queryParams =>
    String(
      queryParams[QP.RECEIPTS_SUMMARY_TYPE] || SUMMARY_SWITCH.RECEIPTS_METRICS
    ).toUpperCase()
);

export const uppercasedHoursSummaryTabSelector = createSelector(
  queryParamsSelector,
  queryParams =>
    String(
      queryParams[QP.HOURS_SUMMARY_TYPE] || SUMMARY_SWITCH.WORTH
    ).toUpperCase()
);

export const uppercasedMultipackSummaryTabSelector = createSelector(
  queryParamsSelector,
  queryParams =>
    String(
      queryParams[QP.MULTIPACK_SUMMARY_TYPE] || SUMMARY_SWITCH.MULTIPACK_TOTAL
    ).toUpperCase()
);

export const uppercasedSummarySubTypeSelector = createSelector(
  queryParamsSelector,
  queryParams =>
    String(
      queryParams[QP.SUMMARY_SUB_TYPE] || SUMMARY_SUB_SWITCH.OWN
    ).toUpperCase()
);

export const aggregatedQueryParamSelector = createSelector(
  queryParamsSelector,
  queryParams => String(queryParams[QP.AGGREGATED])
);

export const lowercasedAggregatedQueryParamSelector = createSelector(
  aggregatedQueryParamSelector,
  aggregated => aggregated.toLocaleLowerCase()
);

export const lflQueryParamSelector = createSelector(
  queryParamsSelector,
  queryParams => String(queryParams[QP.LFL])
);

export const logisticsPeriodTypeSelector = createSelector(
  queryParamsSelector,
  queryParams => String(queryParams[QP.LOGISTICS_VISIBLE_TYPE] || "")
);

export const isLogisticsTableVisibleSelector = createSelector(
  logisticsPeriodTypeSelector,
  logisticsVisibleType =>
    logisticsVisibleType === LOGISTICS_DATA_TYPES_PARAMS.SINGLE_DAY
);

export const dateFromQueryParamSelector = createSelector(
  queryParamsSelector,
  queryParams => {
    if (Array.isArray(queryParams[QP.DATE_FROM])) {
      throw new Error(
        "Invalid dateFrom query parameter, string was expected but array was given"
      );
    }
    return String(queryParams[QP.DATE_FROM] || "");
  }
);

export const dateToQueryParamSelector = createSelector(
  queryParamsSelector,
  queryParams => {
    if (Array.isArray(queryParams[QP.DATE_TO])) {
      throw new Error(
        "Invalid dateTo query parameter, string was expected but array was given"
      );
    }

    return String(queryParams[QP.DATE_TO] || "");
  }
);

export const productQueryParamSelector = createSelector(
  queryParamsSelector,
  queryParams => queryParams[QP.PRODUCT] || ""
);

export const brandQueryParamSelector = createSelector(
  queryParamsSelector,
  queryParams => queryParams[QP.BRANDS] || ""
);
export const subBrandQueryParamSelector = createSelector(
  queryParamsSelector,
  queryParams => queryParams[QP.SUB_BRANDS] || ""
);

export const competitorBrandQueryParamSelector = createSelector(
  queryParamsSelector,
  queryParams => queryParams[QP.COMPETING_BRANDS] || ""
);
export const competitorSubBrandQueryParamSelector = createSelector(
  queryParamsSelector,
  queryParams => queryParams[QP.COMPETING_SUB_BRANDS] || ""
);

export const referenceBrandQueryParamSelector = createSelector(
  queryParamsSelector,
  queryParams => queryParams[QP.REF_BRAND] || ""
);
export const referenceSubBrandQueryParamSelector = createSelector(
  queryParamsSelector,
  queryParams => queryParams[QP.REF_SUBBRAND] || ""
);

export const excludedDaysQueryParamSelector = createSelector(
  queryParamsSelector,
  queryParams => queryParams[QP.EXCLUDED_DAYS] || ""
);

export const reportExportParamsByPeriodSelector = createSelector(
  pathnameSelector,
  searchStringSelector,
  (pathname, search) =>
    memoize((period: string) =>
      generateReportExportParams(pathname, search, period)
    )
);

export const reportExportQueryByPeriodSelector = createSelector(
  reportExportParamsByPeriodSelector,
  reportExportParamsByPeriod =>
    memoize((period: string) =>
      qs.stringify(reportExportParamsByPeriod(period), { arrayFormat: "comma" })
    )
);

export const loginCredentialsSelector = createSelector(
  queryParamsSelector,
  ({ code, state }) =>
    code && state ? { code: String(code), state: String(state) } : null
);

//this param specifies if products with less than 5% distribution should be hidden in RANKING
export const isRanking5DistributionHiddenSelector = createSelector(
  queryParamsSelector,
  queryParams => queryParams[QP.HIDE_5_RANK] === BOOL_STRING_VALUES.TRUE
);

//this param specifies if planogram distribution should be visible on LOGISTICS TABLE
export const isPlanogramHiddenSelector = createSelector(
  queryParamsSelector,
  queryParams => queryParams[QP.HIDE_PLANOGRAM] === BOOL_STRING_VALUES.TRUE
);

export const isSidebarChangeSelector = createSelector(
  queryParamsSelector,
  queryParams => queryParams[QP.SIDEBAR_CHANGE] === BOOL_STRING_VALUES.TRUE
);

export const areTilesHiddenSelector = createSelector(
  queryParamsSelector,
  queryParams => queryParams[QP.HIDE_TILES] === BOOL_STRING_VALUES.TRUE
);

export const compareDateFromQPSelector = createSelector(
  queryParamsSelector,
  queryParams => {
    if (Array.isArray(queryParams[QP.COMPARE_DATE_FROM])) {
      throw new TypeError(
        'Invalid "compare_date_from" param type. Expected string, received array.'
      );
    }

    return queryParams[QP.COMPARE_DATE_FROM] || "";
  }
);

export const compareDateToQPSelector = createSelector(
  queryParamsSelector,
  queryParams => {
    if (Array.isArray(queryParams[QP.COMPARE_DATE_TO])) {
      throw new TypeError(
        'Invalid "compare_date_to" param type. Expected string, received array.'
      );
    }

    return queryParams[QP.COMPARE_DATE_TO] || "";
  }
);

export const includeLeapYearQPSelector = createSelector(
  queryParamsSelector,
  queryParams => queryParams[QP.INCLUDE_LEAP_YEAR] || ""
);

export const dynamicPeriodCheckedQPSelector = createSelector(
  queryParamsSelector,
  queryParams =>
    queryParams[QP.DYNAMIC_PERIOD_CHECKED] || BOOL_STRING_VALUES.FALSE
);

export const dynamicPeriodQPSelector = createSelector(
  queryParamsSelector,
  queryParams => queryParams[QP.DYNAMIC_PERIOD] || ""
);

export const initialDateFromQPSelector = createSelector(
  queryParamsSelector,
  queryParams => queryParams[QP.INITIAL_DATE_FROM] || ""
);

export const initialDateToQPSelector = createSelector(
  queryParamsSelector,
  queryParams => queryParams[QP.INITIAL_DATE_TO] || ""
);

export const groupBySegmentsQPSelector = createSelector(
  queryParamsSelector,
  queryParams => queryParams[QP.GROUP_BY_SEGMENTS] || BOOL_STRING_VALUES.FALSE
);

export const isGroupedBySegmentsSelector = createSelector(
  groupBySegmentsQPSelector,
  groupBySegmentsQP => {
    return String(groupBySegmentsQP).toLowerCase() === BOOL_STRING_VALUES.TRUE;
  }
);

export const activeInsightQPSelector = createSelector(
  queryParamsSelector,
  queryParams => String(queryParams[QP.ACTIVE_INSIGHT] || "")
);

export const insightSectionQPSelector = createSelector(
  queryParamsSelector,
  queryParams => String(queryParams[QP.INSIGHT_STATE] || "") as Section
);

export const promotionsQPSelector = createSelector(
  queryParamsSelector,
  queryParams => queryParams[QP.PROMOTIONS] || ""
);
