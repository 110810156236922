import React from "react";
import { useDispatch, useSelector } from "react-redux";

import qs from "query-string";

import {
  asyncFiltersSelector,
  setAsyncFilterFetchingError as setError,
  setAsyncFilterFetchingFlag as setFlag,
  updateAsyncFilterQuery as updateQuery
} from "pages/Reports/redux/reducers/asyncFiltersReducer";
import { ProductItemApi } from "pages/Reports/redux/reducers/filters/competition/competitionProductsFilterReducer";
import { useFiltersFetchingParams } from "pages/Reports/redux/reducers/filters/hooks/useFiltersFetchingParams";
import {
  ProductHooks,
  ProductItem
} from "pages/Reports/redux/reducers/sweetStateHooks/useProduct";
import { VendorHooks } from "pages/Reports/redux/reducers/sweetStateHooks/useVendor";
import { isCompaniesDropdownMultiChoiceSelector } from "pages/Reports/redux/selectors/reportsSelectors";
import { mapProductsResponse } from "pages/Reports/redux/utils";
import { getBackendReportType } from "pages/Reports/utils";
import { isPowerUserSelector } from "store/reducers/userReducer";
import {
  pathnameSelector,
  productQueryParamSelector,
  vendorIdQueryParamSelector
} from "store/selectors/routerSelectors";
import { HTTP, REST_API_ENDPOINTS } from "utils";

type ProductRequestParams = {
  report_type: string;
  categories: string;
  category_level: string;
  attributes?: string;
  vendor_id?: string;
  companies?: string;
  brands?: string;
  sub_brands?: string;
};

const fetchProducts = async (params: ProductRequestParams) => {
  const response = await HTTP.get<ProductItemApi[]>(
    REST_API_ENDPOINTS.PRODUCTS,
    {
      params
    }
  );
  return mapProductsResponse(response);
};

const useProductFilterParams = () => {
  const isFormPristine = useSelector(asyncFiltersSelector).isFormPristine;
  const isMultiChoice = useSelector(isCompaniesDropdownMultiChoiceSelector);
  const pathname = useSelector(pathnameSelector);
  const [selectedCompany] = VendorHooks.useSelectedVendorsIds(isMultiChoice);

  const isPowerUser = useSelector(isPowerUserSelector);

  const reportTypeParam = getBackendReportType(pathname);

  const selectedCompanyQP = useSelector(vendorIdQueryParamSelector)
    .toString()
    .split(",");

  const areMultipleCompaniesSelected = isFormPristine
    ? selectedCompanyQP.length > 1
    : selectedCompany.length > 1;

  const params = useFiltersFetchingParams([
    "category",
    "categoryLevel",
    "attributes",
    "vendor",
    "brand",
    "subBrand"
  ]);

  const base: ProductRequestParams = {
    report_type: reportTypeParam,
    categories: params.category,
    category_level: params.categoryLevel,
    brands: params.brand,
    sub_brands: params.subBrand
  };

  if (params.attributes) {
    Object.assign(base, { attributes: params.attributes });
  }

  if (areMultipleCompaniesSelected) {
    Object.assign(base, { companies: params.vendor });
    return base;
  }

  if (isPowerUser) {
    Object.assign(base, { vendor_id: params.vendor });
  }

  return base;
};

const useFetchingFlag = (
  params: {
    categories: string;
    category_level: string;
  },
  isDisabled: boolean
) => {
  const isLoading = useSelector(asyncFiltersSelector).fetching.products;
  const lastQuery = useSelector(asyncFiltersSelector).query.products;

  const query = qs.stringify(params);
  if (isLoading || isDisabled) {
    return false;
  }

  if (query === lastQuery) {
    return false;
  }

  if (!params.categories || !params.category_level) {
    return false;
  }

  return true;
};

const useQueryParamsSelections = () => {
  const [state, actions] = ProductHooks.useProduct();
  const isPristine = useSelector(asyncFiltersSelector).isFormPristine;
  const productsQP = useSelector(productQueryParamSelector);

  React.useEffect(() => {
    if (isPristine) {
      actions.checkPristineSelectionsAfterFetching(state.all, productsQP);
    }
  }, [isPristine, productsQP, actions, state.all]);
};

const useProductFetching = (isDisabled: boolean) => {
  const filter = "products";
  const dispatch = useDispatch();

  const [, actions] = ProductHooks.useProduct();
  const params = useProductFilterParams();
  const isFetchingPossible = useFetchingFlag(params, isDisabled);
  const productsQP = useSelector(productQueryParamSelector);

  const isPristine = useSelector(asyncFiltersSelector).isFormPristine;

  const callback = React.useCallback(
    (products: ProductItem[]) => {
      actions.updateAllProducts(products);
      if (isPristine) {
        actions.checkPristineSelectionsAfterFetching(products, productsQP);
      }
      actions.checkSelectionsAfterFetching(products.map(cmp => cmp.value));
      actions.sortProducts();
    },
    [isPristine, actions, productsQP]
  );
  useQueryParamsSelections();

  React.useEffect(() => {
    if (!isFetchingPossible) {
      return;
    }

    const fetch = async () => {
      try {
        dispatch(updateQuery({ filter, query: qs.stringify(params) }));

        dispatch(setFlag({ filter, isFetching: true }));
        const products = await fetchProducts(params);
        dispatch(setError({ filter, status: "" }));

        return products;
      } catch (e) {
        dispatch(setError({ filter, status: "Błąd pobierania produktów" }));
        return [];
      } finally {
        dispatch(setFlag({ filter, isFetching: false }));
      }
    };

    fetch().then(callback);
  }, [dispatch, isFetchingPossible, params, callback]);
};

export const useProductBehaviour = (isDisabled: boolean) => {
  useProductFetching(isDisabled);
  useQueryParamsSelections();
};
