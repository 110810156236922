import * as React from "react";
import { useSelector } from "react-redux";

import { checkPromotionOccurence } from "components/atoms/PromotionContent/utils";
import { ChartData, Dimensions, Margins } from "components/D3/types";
import {
  FetchedPromotionTypes,
  usePromotionsForDates
} from "hooks/usePromotionsForDates";
import { useChartScales } from "pages/Reports/partials/Chart/StandardChart/utils";
import { fetchingStatusPromotionsSelector } from "pages/Reports/redux/selectors/promotionsSelectors";
import {
  chartPeriodSelector,
  uppercasedPromotionChartDataTypeSelector
} from "store/selectors/routerSelectors";
import { newDateByTimezone } from "store/utils";
import { CHART_DATA_TYPE, PROMOTION_COLORS } from "utils";

type Props = {
  margins?: Margins;
  dimensions: Dimensions;
  areAllLinesHidden: boolean;
  domain: string[];
  lineChart: ChartData;
  isHistoricalGroupedChart: boolean;
};

export const PromotionsChart: React.FC<Props> = ({
  margins = {
    top: -20,
    left: 0,
    bottom: 0,
    right: 0
  },
  dimensions,
  areAllLinesHidden,
  domain,
  lineChart,
  isHistoricalGroupedChart
}) => {
  const { xScaleBand } = useChartScales(lineChart, dimensions.width);
  const isFetchingPromotions = useSelector(fetchingStatusPromotionsSelector);
  const promotionChartDataTypes = useSelector(
    uppercasedPromotionChartDataTypeSelector
  );
  const promotionContainerRef = React.useRef(null);
  const promotionData = usePromotionsForDates({
    fetchedPromotionTypes: isHistoricalGroupedChart
      ? FetchedPromotionTypes.HISTORICAL
      : FetchedPromotionTypes.REGULAR
  });

  const chartPeriod = useSelector(chartPeriodSelector);

  const noSelectedPromotions =
    promotionChartDataTypes.filter(
      // @ts-ignore promotion can have NONE value, but it's not included in types
      promotion => promotion !== CHART_DATA_TYPE.NONE
    ).length === 0;

  const shouldHidePromotions =
    noSelectedPromotions || areAllLinesHidden || isFetchingPromotions;

  if (shouldHidePromotions) {
    return null;
  }

  const { left, top } = margins;
  const { height } = dimensions;

  return (
    <g ref={promotionContainerRef} transform={`translate(${left}, ${top})`}>
      <defs>
        {Object.entries(PROMOTION_COLORS).map(([type, color]) => (
          <linearGradient
            id={`gradient-promo-${type}`}
            x1="0"
            x2="0"
            y1="0"
            y2="1"
            key={color}
          >
            <stop offset="0%" stopColor={color} stopOpacity="0.3" />
            <stop offset="100%" stopColor="white" stopOpacity="0.3" />
          </linearGradient>
        ))}
      </defs>
      {domain.map((date, dateIndex) => {
        const currentDate = newDateByTimezone(date);
        const promotionsInCurrentDate = checkPromotionOccurence(
          currentDate,
          promotionData,
          chartPeriod
        );

        if (promotionsInCurrentDate.length === 0) return null;

        return promotionsInCurrentDate.map((promotion, promoIndex) => (
          <rect
            key={`gradient-promo-${promoIndex}-${dateIndex}`}
            x={xScaleBand(date || "") || 0}
            y="20"
            width={xScaleBand.bandwidth()}
            height={height}
            fill={`url(#gradient-promo-${promotion.display})`}
          />
        ));
      })}
    </g>
  );
};
