import { ThunkAction } from "redux-thunk";

import { AppState } from "store";
import {
  reportExportParamsByPeriodSelector,
  reportExportQueryByPeriodSelector
} from "store/selectors/routerSelectors";
import { HTTP, REST_API_ENDPOINTS } from "utils";

import {
  sellReportLoadingSelector,
  sellReportUrlByPeriodSelector
} from "../selectors/reportSelectors";
import { SellsReportExportApi } from "../types/reportTypes";

export const SET_SELL_REPORT_LOADING = "Sell.SET_SELL_REPORT_LOADING";
export const UPDATE_SELL_REPORTS_URLS = "Sell.UPDATE_SELL_REPORTS_URLS";

export const setSellReportLoading = (payload: boolean) => ({
  type: SET_SELL_REPORT_LOADING,
  payload
});

export const updateSellReportsUrls = (payload: {
  [query: string]: string;
}) => ({
  type: UPDATE_SELL_REPORTS_URLS,
  payload
});

export const generateSellsReport: (
  period: string
) => ThunkAction<void, AppState, undefined, any> = period => async (
  dispatch,
  getState
) => {
  const state = getState();
  const isFetching = sellReportLoadingSelector(state);
  if (isFetching) return;

  const url = sellReportUrlByPeriodSelector(state)(period);
  if (url) return;

  const query = reportExportQueryByPeriodSelector(state)(period);
  const params = reportExportParamsByPeriodSelector(state)(period);

  dispatch(setSellReportLoading(true));
  try {
    const response = await HTTP.get<SellsReportExportApi>(
      REST_API_ENDPOINTS.REPORTS.SALES_EXPORT,
      {
        params
      }
    );

    if (!response?.data?.url) {
      throw new Error(
        "Invalid API response: Sell report url was not provided."
      );
    }

    dispatch(setSellReportLoading(false));
    dispatch(
      updateSellReportsUrls({
        [query]: response.data.url
      })
    );

    window.location.assign(response.data.url);
  } catch (err) {
    dispatch(setSellReportLoading(false));
    console.error(err.message);
  }
};
