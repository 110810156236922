import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import qs from "query-string";

import {
  asyncFiltersSelector,
  setAsyncFilterFetchingError as setError,
  setAsyncFilterFetchingFlag as setFlag,
  updateAsyncFilterQuery as updateQuery
} from "pages/Reports/redux/reducers/asyncFiltersReducer";
import {
  AttributesHooks,
  AttributesItemApi
} from "pages/Reports/redux/reducers/sweetStateHooks/useAttributes";
import { queryParamsSelector } from "store/selectors/routerSelectors";
import { HTTP, REST_API_ENDPOINTS } from "utils";

import { useFiltersFetchingParams } from "./useFiltersFetchingParams";

type RequestParams = {
  categories: string;
  category_level: string;
  tier: string;
};

const fetchAttributes = (params: RequestParams) => {
  return HTTP.get<Promise<AttributesItemApi[]>>(REST_API_ENDPOINTS.ATTRIBUTES, {
    params
  });
};

const useFilterParams = (): RequestParams => {
  const params = useFiltersFetchingParams([
    "category",
    "categoryLevel",
    "tier"
  ]);

  return {
    categories: params.category,
    category_level: params.categoryLevel,
    tier: params.tier
  };
};

const useFetchingFlag = (params: RequestParams, isDisabled: boolean) => {
  const isLoading = useSelector(asyncFiltersSelector).fetching.attributes;
  const lastQuery = useSelector(asyncFiltersSelector).query.attributes;
  const query = qs.stringify(params);

  if (isLoading || isDisabled) {
    return false;
  }

  if (query === lastQuery) {
    return false;
  }

  if (!params.categories || !params.category_level || !params.tier) {
    return false;
  }

  return true;
};

export const useAttributesBehaviour = (isDisabled: boolean) => {
  const isPristine = useSelector(asyncFiltersSelector).isFormPristine;
  const queryParams = useSelector(queryParamsSelector);
  const dispatch = useDispatch();
  const params = useFilterParams();
  const isFetchingPossible = useFetchingFlag(params, isDisabled);
  const [, actions] = AttributesHooks.useAttributes();

  const saveAndSelectCallback = useCallback(
    (attributes: AttributesItemApi[]) => {
      actions.updateAllAttributes(attributes);

      if (isPristine) {
        actions.checkPristineSelectionsAfterFetching(attributes, queryParams);
      } else {
        actions.checkSelectionsAfterFetching(
          attributes.map(attribute => String(attribute.attribute_value_id))
        );
      }
    },
    [actions, isPristine, queryParams]
  );

  useEffect(() => {
    if (!isFetchingPossible) return;

    const fetch = async () => {
      const filter = "attributes";

      try {
        dispatch(updateQuery({ filter, query: qs.stringify(params) }));
        dispatch(setFlag({ filter, isFetching: true }));
        const { data: attributes } = await fetchAttributes(params);
        dispatch(setError({ filter, status: "" }));
        return attributes;
      } catch (error) {
        dispatch(setError({ filter, status: "Błąd pobierania atrybutów" }));
        return [];
      } finally {
        dispatch(setFlag({ filter, isFetching: false }));
      }
    };

    fetch().then(saveAndSelectCallback);
  }, [dispatch, isFetchingPossible, params, saveAndSelectCallback]);
};
