import { createSelector } from "reselect";

import { getSelectedCountLabel } from "pages/Reports/partials/ReportsSidebar/ReportsFilterForm/utils";
import { CompetitorProductRequestParams } from "pages/Reports/redux/reducers/filters/hooks/useCompetitorProductFetching";
import { ProductItem } from "pages/Reports/redux/reducers/sweetStateHooks/useProduct";
import {
  mapCompetitionProductsResponse,
  sortProductsBySelectionOrder
} from "pages/Reports/redux/utils";
import { sortProductsByWithdrawness } from "pages/Reports/redux/utils/sortProductsByWithdrawness";
import { AppState } from "store";
import { RESET_DATA, resetData } from "store/actions/appActions";
import { competitionProductsQueryParamSelector } from "store/selectors/routerSelectors";
import { toArray } from "store/utils";
import { isAlreadySelected, isIncludedInInput } from "store/utils/filtersUtils";
import { HTTP, isProductWithdrawn, REST_API_ENDPOINTS } from "utils";
import { Thunk, Values } from "utils/types";

import { Parent } from "../categoryFilters/categoryFiltersActions";

// CONST
const UPDATE_ALL_COMPETITION_PRODUCTS = "REPORTS.UPDATE_ALL_COMPETITION_PRODUCTS" as const;
const UPDATE_SELECTED_COMPETITION_PRODUCTS = "REPORTS.UPDATE_SELECTED_COMPETITION_PRODUCTS" as const;
const TOGGLE_UNACTIVE_COMPETITION_PRODUCTS_BOTTOM = "REPORTS.TOGGLE_UNACTIVE_COMPETITION_PRODUCTS_BOTTOM" as const;

// TYPES
export type ProductItemApi = {
  material: number;
  display: string;
  category?: Parent | null;
  ean?: string;
};

type CompetitionProducts = {
  allItems: ProductItem[];
  selectedItems: ProductItem[];
  loadingStatus: boolean;
  query: string;
  unactiveCompetitionProductsBottom: boolean;
};

type UpdateSelectedCompetitionProductsAction = {
  type: typeof UPDATE_SELECTED_COMPETITION_PRODUCTS;
  payload: ProductItem[];
};

export type UpdateAllCompetitionProductAction = {
  type: typeof UPDATE_ALL_COMPETITION_PRODUCTS;
  payload: ProductItem[];
};

type UpdateSelectedCompetitionProductAction = {
  type: typeof UPDATE_SELECTED_COMPETITION_PRODUCTS;
  payload: ProductItem[];
};

type ToggleUnactiveCompetitionProductsBottomAction = {
  type: typeof TOGGLE_UNACTIVE_COMPETITION_PRODUCTS_BOTTOM;
  payload: boolean;
};

type CompetitionProductsActions = {
  UPDATE_COMPETITION_PRODUCTS: UpdateAllCompetitionProductAction;
  UPDATE_SELECTED_COMPETITION_PRODUCTS: UpdateSelectedCompetitionProductAction;
  TOGGLE_UNACTIVE_COMPETITION_PRODUCTS_BOTTOM: ToggleUnactiveCompetitionProductsBottomAction;
};

type ClearCompetitionProductsFilterAction = UpdateSelectedCompetitionProductsAction;

//SELECTORS
const competitionProductsSelector = (state: AppState): CompetitionProducts =>
  state.reports.competitionFilters.competitionProducts || initialState;

export const unactiveCompetitionProductsBottomSelector = createSelector(
  competitionProductsSelector,
  ({ unactiveCompetitionProductsBottom }) => unactiveCompetitionProductsBottom
);

export const allCompetitionProductsSelector = createSelector<
  AppState,
  CompetitionProducts,
  ProductItem[]
>(competitionProductsSelector, ({ allItems }) => allItems);

export const selectedCompetitionProductsSelector = createSelector<
  AppState,
  CompetitionProducts,
  ProductItem[]
>(competitionProductsSelector, ({ selectedItems }) => selectedItems);

export const selectedCompetitionProductsIdsSelector = createSelector<
  AppState,
  ProductItem[],
  string[]
>(selectedCompetitionProductsSelector, selectedCompetitionProducts =>
  selectedCompetitionProducts.map(({ value }) => String(value))
);

export const selectedCompetitionProductsLabelSelector = createSelector<
  AppState,
  ProductItem[],
  ProductItem[],
  string
>(
  selectedCompetitionProductsSelector,
  allCompetitionProductsSelector,
  (selectedCompetitionProducts, allCompetitionProducts) =>
    getSelectedCountLabel(
      selectedCompetitionProducts.length,
      allCompetitionProducts.length
    )
);

// ACTIONS
const updateSelectedCompetitionProducts = (
  payload: ProductItem[]
): UpdateSelectedCompetitionProductAction => ({
  type: UPDATE_SELECTED_COMPETITION_PRODUCTS,
  payload
});

export const updateAllCompetitionProducts = (
  payload: ProductItem[]
): UpdateAllCompetitionProductAction => ({
  type: UPDATE_ALL_COMPETITION_PRODUCTS,
  payload
});

export const toggleUnactiveCompetitionProductsBottom = (
  payload: boolean
): ToggleUnactiveCompetitionProductsBottomAction => ({
  type: TOGGLE_UNACTIVE_COMPETITION_PRODUCTS_BOTTOM,
  payload
});

export const fetchCompetitorsProducts = async (
  params: CompetitorProductRequestParams
) => {
  const response = await HTTP.get<ProductItemApi[]>(
    REST_API_ENDPOINTS.PRODUCTS,
    {
      params
    }
  );

  return mapCompetitionProductsResponse(response?.data || []);
};

export const checkCompetitorProductsPristineSelectionsAfterFetching: () => Thunk<
  Values<CompetitionProductsActions>
> = () => (dispatch, getState) => {
  const state = getState();
  const products = allCompetitionProductsSelector(state);
  const selectedCompetitionProductsIdsQP = competitionProductsQueryParamSelector(
    state
  );
  const responseIds = products.map(prod => prod.value);
  const productsQP: string[] = toArray(selectedCompetitionProductsIdsQP);
  const preselectedProducts = productsQP.filter(company =>
    responseIds.includes(Number(company))
  );

  if (preselectedProducts.length) {
    const productsToSelect: ProductItem[] = preselectedProducts
      .map(product => {
        const existing = products.find(
          fetched => fetched.value === Number(product)
        );
        if (existing) {
          return {
            label: existing.label,
            value: existing.value
          };
        }
        return {
          label: "",
          value: 0
        };
      })
      .filter(prod => prod !== null);
    dispatch(updateSelectedCompetitionProducts(productsToSelect));
  }
};

export const checkCompetitionProductsSelectionsAfterFetching: (
  ids: number[]
) => Thunk<Values<CompetitionProductsActions>> = ids => (
  dispatch,
  getState
) => {
  const selected = selectedCompetitionProductsSelector(getState());
  const remainingProducts = selected.filter(prod => ids.includes(prod.value));
  dispatch(updateSelectedCompetitionProducts(remainingProducts));
};

export const handleCompetitionProductSelection = (
  selectedProduct: ProductItem | null
): Thunk<UpdateSelectedCompetitionProductsAction> => (dispatch, getState) => {
  if (!selectedProduct) {
    return dispatch(updateSelectedCompetitionProducts([]));
  }

  const selectedCompetitionProducts = selectedCompetitionProductsSelector(
    getState()
  );

  const isItemAlreadySelected = selectedCompetitionProducts.some(
    ({ value }) => value === selectedProduct.value
  );

  if (isItemAlreadySelected) {
    return dispatch(
      updateSelectedCompetitionProducts(
        selectedCompetitionProducts.filter(
          ({ value }) => value !== selectedProduct.value
        )
      )
    );
  }

  dispatch(
    updateSelectedCompetitionProducts([
      ...selectedCompetitionProducts,
      selectedProduct
    ])
  );
};

export const sortCompetitionProductsBySelectionOrder: () => Thunk<
  UpdateAllCompetitionProductAction
> = () => (dispatch, getState) => {
  const state = getState();

  const allCompetitionProducts = allCompetitionProductsSelector(state);
  const selectedCompetitionProductsIds = selectedCompetitionProductsIdsSelector(
    state
  );
  const unactiveCompetitionProductsBottom = unactiveCompetitionProductsBottomSelector(
    state
  );

  const sortedAllCompetitionProducts = sortProductsBySelectionOrder(
    selectedCompetitionProductsIds.map(id => Number(id)),
    allCompetitionProducts
  );

  const sortedByWithdrawness = unactiveCompetitionProductsBottom
    ? sortProductsByWithdrawness(sortedAllCompetitionProducts)
    : sortedAllCompetitionProducts;

  dispatch(updateAllCompetitionProducts(sortedByWithdrawness));
};

export const clearCompetitionProductsFilter = (): Thunk<ClearCompetitionProductsFilterAction> => dispatch => {
  dispatch(updateSelectedCompetitionProducts([]));
};

export const selectAllActiveOrInactiveCompetitionProducts = (
  inputValue: string,
  selectActiveItems = false
): Thunk<UpdateSelectedCompetitionProductsAction> => (dispatch, getState) => {
  const state = getState();
  const allCompetitionProducts = allCompetitionProductsSelector(state);
  const selectedCompetitionProducts = selectedCompetitionProductsSelector(
    state
  );

  dispatch(
    updateSelectedCompetitionProducts([
      ...selectedCompetitionProducts,
      ...allCompetitionProducts.filter(item => {
        const shouldBeReturned = selectActiveItems
          ? !isProductWithdrawn(item.label)
          : isProductWithdrawn(item.label);

        return (
          !isAlreadySelected(selectedCompetitionProducts, item) &&
          isIncludedInInput(inputValue, item) &&
          shouldBeReturned
        );
      })
    ])
  );
};

//REDUCER
const initialState: CompetitionProducts = {
  loadingStatus: false,
  allItems: [],
  selectedItems: [],
  query: "",
  unactiveCompetitionProductsBottom: false
};

export const competitionProductsFilterReducer = (
  state: CompetitionProducts = initialState,
  action: Values<CompetitionProductsActions> | ReturnType<typeof resetData>
): CompetitionProducts => {
  switch (action.type) {
    case UPDATE_ALL_COMPETITION_PRODUCTS:
      return {
        ...state,
        allItems: action.payload
      };
    case UPDATE_SELECTED_COMPETITION_PRODUCTS:
      return {
        ...state,
        selectedItems: action.payload
      };
    case TOGGLE_UNACTIVE_COMPETITION_PRODUCTS_BOTTOM:
      return {
        ...state,
        unactiveCompetitionProductsBottom: action.payload
      };
    case RESET_DATA:
      return initialState;

    default:
      return state;
  }
};
