import * as React from "react";
import { useDispatch, useSelector } from "react-redux";

import { ChartData } from "components/D3/types";
import { TourHooks } from "components/organisms/Tour/hooks/useTours";
import { BarsGroup } from "pages/Reports/partials/Chart/StandardChart/BarsGroup";
import { SegmentsBarsGroup } from "pages/Reports/partials/Chart/StandardChart/SegmentsBarsGroup";
import {
  ChartOnClickAction,
  ChartOnHoverAction,
  pinnedTimepointSelector,
  updateHoveredTimepointPosition
} from "pages/Reports/redux/reducers/chartReducer";
import { updateCurrentChart } from "pages/Reports/sections/Segments/redux/reducers/segmentsChartReducer";
import { REPORTS_PATHS } from "pages/Reports/utils";
import {
  isGroupedBySegmentsSelector,
  pathnameSelector
} from "store/selectors/routerSelectors";

type Props = {
  chartId: string;
  showBarChart: Boolean;
  lineChart: ChartData;
  domain: string[];
  height: number;
  width: number;
  onTimelineHover: ChartOnHoverAction;
  onTimelineClick: ChartOnClickAction;
  originalWidth: number;
};

export const BarsChart: React.FC<Props> = ({
  chartId,
  showBarChart,
  lineChart,
  domain,
  height,
  width,
  onTimelineHover,
  onTimelineClick,
  originalWidth
}) => {
  const dispatch = useDispatch();
  const pinnedTimepoint = useSelector(pinnedTimepointSelector);
  const pathname = useSelector(pathnameSelector);
  const isGroupedBySegments = useSelector(isGroupedBySegmentsSelector);
  const [isSidebarTourHidden] = TourHooks.useIsSidebarTourHidden();

  const isSegmentsPage = pathname.includes(REPORTS_PATHS.SEGMENTS);
  const barsCount = isGroupedBySegments
    ? lineChart.leftChart?.chart.length || 1
    : domain.length;

  const onMouseMoveBar = (index: number) => {
    if (isSegmentsPage && pinnedTimepoint !== null) return;

    const bandwidth = width / barsCount;
    const x = bandwidth * index + bandwidth / 2;

    dispatch(onTimelineHover(isSegmentsPage ? index : 0));
    dispatch(updateHoveredTimepointPosition(x));
    dispatch(updateCurrentChart(chartId));
  };

  const onMouseLeaveBar = () => {
    if (isSegmentsPage && pinnedTimepoint !== null) return;
    if (!isSidebarTourHidden) return;

    dispatch(onTimelineHover(null));
    dispatch(updateCurrentChart(""));
  };

  React.useEffect(() => {
    if (pinnedTimepoint === null) {
      dispatch(updateCurrentChart(""));
    }
  }, [dispatch, pinnedTimepoint]);

  if (!showBarChart) {
    return null;
  }

  return isSegmentsPage && !isGroupedBySegments ? (
    <SegmentsBarsGroup
      lineChart={lineChart}
      height={height}
      onMouseMoveBar={onMouseMoveBar}
      onMouseLeaveBar={onMouseLeaveBar}
      onTimelineClick={onTimelineClick}
      originalWidth={originalWidth}
    />
  ) : (
    <BarsGroup
      lineChart={lineChart}
      height={height}
      width={width}
      onMouseMoveBar={onMouseMoveBar}
      onMouseLeaveBar={onMouseLeaveBar}
      onTimelineClick={onTimelineClick}
      originalWidth={originalWidth}
    />
  );
};
