import { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import cn from "classnames";
import { isSameDay } from "date-fns";
import { ParsedQuery } from "query-string";

import { Button, Text } from "components/atoms";
import { Reload } from "components/atoms/Icon";
import { ConfirmModal } from "components/organisms";
import DateDropdown from "components/organisms/DateDropdown/DateDropdown";
import { IDS } from "components/organisms/Tour";
import { useDefaultQueryParams } from "hooks";
import { useQueryWithInsightId } from "hooks/useUpdateDataActions";
import { BrandDropdown } from "pages/Reports/partials/ReportsSidebar/ReportsFilterForm/BrandDropdown/BrandDropdown";
import { ClientTypeChoiceGroup } from "pages/Reports/partials/ReportsSidebar/ReportsFilterForm/ClientTypeChoiceGroup/ClientTypeDropdown";
import { CompetitionCompanyDropdown } from "pages/Reports/partials/ReportsSidebar/ReportsFilterForm/CompetitorsFilters/CompetitionCompanyDropdown/CompetitionCompanyDropdown";
import { CompetitionProductDropdown } from "pages/Reports/partials/ReportsSidebar/ReportsFilterForm/CompetitorsFilters/CompetitionProductDropdown/CompetitionProductDropdown";
import { DROPDOWN_LABELS } from "pages/Reports/partials/ReportsSidebar/ReportsFilterForm/constants";
import { CountyDropdown } from "pages/Reports/partials/ReportsSidebar/ReportsFilterForm/CountyDropdown/CountyDropdown";
import { DelayCompetition } from "pages/Reports/partials/ReportsSidebar/ReportsFilterForm/DelayCompetition/DelayCompetiton";
import { ExportDataType } from "pages/Reports/partials/ReportsSidebar/ReportsFilterForm/ExportDataType/ExportDataType";
import { LFLChoiceGroup } from "pages/Reports/partials/ReportsSidebar/ReportsFilterForm/LFLChoiceGroup/LFLChoiceGroup";
import { ShopTypeDropdown } from "pages/Reports/partials/ReportsSidebar/ReportsFilterForm/ShopTypeDropdown/ShopTypeDropdown";
import { SuperUserCompanies } from "pages/Reports/partials/ReportsSidebar/ReportsFilterForm/SuperUserComapnies/SuperUserCompanies";
import { TierChoice } from "pages/Reports/partials/ReportsSidebar/ReportsFilterForm/TierChoice/TierChoice";
import { TimeAggregationDropdown } from "pages/Reports/partials/ReportsSidebar/ReportsFilterForm/TimeAggregationDropdown/TimeAggregationDropdown";
import {
  asyncFiltersSelector,
  setFilterFormPristine
} from "pages/Reports/redux/reducers/asyncFiltersReducer";
import { updatePinnedTimepoint } from "pages/Reports/redux/reducers/chartReducer";
import { selectedCompetitionCompaniesSelector } from "pages/Reports/redux/reducers/filters/competition/competitionCompaniesFilterReducer";
import { selectedCompetitionProductsSelector } from "pages/Reports/redux/reducers/filters/competition/competitionProductsFilterReducer";
import {
  useDisabledSubmit,
  useSelectedFilters
} from "pages/Reports/redux/reducers/filters/filtersReducer";
import { FILTER_NAMES } from "pages/Reports/redux/reducers/filters/hooks/constants";
import { useDisabledFilters } from "pages/Reports/redux/reducers/filters/hooks/useDisabledFilters";
import { useFiltersClear } from "pages/Reports/redux/reducers/filters/hooks/useFiltersClear";
import { useVisibleList } from "pages/Reports/redux/reducers/filters/hooks/useVisibleFilters";
import { BrandType } from "pages/Reports/redux/reducers/sweetStateHooks/useBrand";
import { CategoryHooks } from "pages/Reports/redux/reducers/sweetStateHooks/useCategory";
import { ProductHooks } from "pages/Reports/redux/reducers/sweetStateHooks/useProduct";
import { PromotionsHooks } from "pages/Reports/redux/reducers/sweetStateHooks/usePromotions";
import {
  getSelectedCompanies,
  VendorHooks
} from "pages/Reports/redux/reducers/sweetStateHooks/useVendor";
import { isCompaniesDropdownMultiChoiceSelector } from "pages/Reports/redux/selectors/reportsSelectors";
import { generateExportData } from "pages/Reports/sections/Export/redux/reducers/exportReducer";
import { setLogisticsTableDate } from "pages/Reports/sections/Logistics/redux/reducers/logisticsReportReducer";
import { REPORTS_FULL_PATHS } from "pages/Reports/utils";
import { updateQueryParams } from "store/actions/routerActions";
import { userTiersAvailableSelector } from "store/reducers/userReducer";
import {
  firstChartDataTypeSelector,
  pathnameSelector,
  queryParamsSelector,
  secondChartDataTypeSelector
} from "store/selectors/routerSelectors";
import { newDateByTimezone } from "store/utils";
import { ICON_SIZES, isThisPage, LOGISTICS_DATA_TYPES_PARAMS } from "utils";
import {
  APP_PATHS,
  CHART_DATA_TYPE,
  FILTER_SECTIONS,
  isAttributesEnabled,
  isMetaSegmentFilterEnabled
} from "utils/constants";
import { QP } from "utils/defaultQueryParams";
import {
  pushChartMetricsEvent,
  pushFiltersEvent
} from "utils/googleTagManager/dataLayer";

import { AggregateReferenceBrands } from "./AggregateReferenceBrands/AggregateReferenceBrands";
import { AggregationFilters } from "./AggregationFilters/AggregationFilters";
import { AttributesDropdown } from "./AttributesDropdown/AttributesDropdown";
import { AttributesHierarchy } from "./AttributesHierarchy/AttributesHierarchy";
import { CategoryDropdownGroup } from "./CategoryDropdown/CategoryDropdownGroup";
import { FiltersSectionToggle } from "./FiltersSectionToggle/FiltersSectionToggle";
import { useResetHiddenLines } from "./hooks/useResetHiddenLines";
import { useSubmitDataLayer } from "./hooks/useSubmitDataLayer";
import { ProductDropdown } from "./ProductDropdown/ProductDropdown";
import { ProductHierarchy } from "./ProductHierarchy/ProductHierarchy";
import { PromotionsDropdown } from "./PromotionsDropdown/PromotionsDropdown";
import { ReferenceCategories } from "./ReferenceCategories/ReferenceCategories";
import s from "./reportsFilterForm.module.scss";
import { ShopTypeMetaDropdown } from "./ShopTypeMetaDropdown/ShopTypeMetaDropdown";
import { SuperUserSettings } from "./SuperUserSettings/SuperUserSettings";
import { VoivodeshipDropdown } from "./VoivodeshipDropdown/VoivodeshipDropdown";

const { INSIGHTS, EXPORT } = APP_PATHS;
const { LOGISTICS_PATH } = REPORTS_FULL_PATHS;
const { NONE } = CHART_DATA_TYPE;

export const ReportsFilterForm = () => {
  const [state] = VendorHooks.useVendor();
  const [isClearPrompted, promptClear] = useState(false);
  const bottomRef = useRef<HTMLDivElement>(null);
  const firstDataType = useSelector(firstChartDataTypeSelector);
  const secondDataType = useSelector(secondChartDataTypeSelector);

  const dispatch = useDispatch();

  const userTiersAvailable = useSelector(userTiersAvailableSelector);
  const currentQueryParameters = useSelector(queryParamsSelector);
  const selectedFiltersQueryParameters = useSelectedFilters();
  const [
    areSelectedCategoriesFake
  ] = CategoryHooks.useSelectedCategoriesFakeIndicator();

  const selectedCompetitorCompanies = useSelector(
    selectedCompetitionCompaniesSelector
  );
  const selectedCompetitorProducts = useSelector(
    selectedCompetitionProductsSelector
  );

  const isSubmitButtonDisabled = useDisabledSubmit();
  const isMultiChoice = useSelector(isCompaniesDropdownMultiChoiceSelector);

  const selectedCompany = getSelectedCompanies(state, isMultiChoice);

  const isLoading = useSelector(asyncFiltersSelector).fetching;
  const pathname = useSelector(pathnameSelector);
  const isExportPage = isThisPage(pathname, EXPORT);
  const isLogisticsPage = isThisPage(pathname, LOGISTICS_PATH);
  const isInsightsPage = isThisPage(pathname, INSIGHTS);
  const [selectedCompaniesLabel] = VendorHooks.useVendorLabel(isMultiChoice);

  const [selectedL1Items] = CategoryHooks.useSelectedCategories(1);
  const [selectedL2Items] = CategoryHooks.useSelectedCategories(2);
  const [selectedL3Items] = CategoryHooks.useSelectedCategories(3);

  const [selectedProductsLabel] = ProductHooks.useProductLabel([
    selectedL1Items,
    selectedL2Items,
    selectedL3Items
  ]);

  const [selectedPromotionsLabel] = PromotionsHooks.usePromotionsLabel();

  const { clearInsightId } = useQueryWithInsightId();
  const { resetHiddenLines } = useResetHiddenLines();
  const { pushSubmitDataLayer } = useSubmitDataLayer(isInsightsPage);

  const getSubmitButtonText = () => {
    if (isExportPage) return "Generuj plik";
    if (isInsightsPage) return "Pokaż Insights";
    return "Pokaż raport";
  };

  const clearAllFilters = useFiltersClear();
  const defaultQueryParams = useDefaultQueryParams();

  const adjustLogisticsVisibleType = (params: ParsedQuery) => {
    const dateFrom = newDateByTimezone(params[QP.DATE_FROM] as string);
    const dateTo = newDateByTimezone(params[QP.DATE_TO] as string);
    const isSameDate = isSameDay(dateFrom, dateTo);

    return {
      ...params,
      [QP.LOGISTICS_VISIBLE_TYPE]: isSameDate
        ? LOGISTICS_DATA_TYPES_PARAMS.SINGLE_DAY
        : LOGISTICS_DATA_TYPES_PARAMS.HISTORY
    };
  };

  const updateParams = () => {
    let params: ParsedQuery = {
      ...currentQueryParameters,
      ...selectedFiltersQueryParameters
    };

    if (isLogisticsPage) {
      params = adjustLogisticsVisibleType(params);

      const dateTo = newDateByTimezone(params.date_to as string);
      dispatch(setLogisticsTableDate(dateTo));
    }

    // PMD-2652 - prevent user from seeing NO_DATA_TYPE_SELECTED after fetching report
    if (!isExportPage && params[QP.CHART_DATA_TYPE_FIRST] === NONE) {
      params[QP.CHART_DATA_TYPE_FIRST] = defaultQueryParams[
        QP.CHART_DATA_TYPE_FIRST
      ] as string;
    }

    dispatch(updateQueryParams(params, "push"));
  };

  const handleSubmit = () => {
    dispatch(updatePinnedTimepoint(null));
    resetHiddenLines();
    updateParams();
    if (!isInsightsPage) {
      pushChartMetricsEvent(firstDataType, secondDataType, true);
    }
    pushSubmitDataLayer();
  };

  const handleExport = () => {
    updateParams();
    dispatch(generateExportData(selectedFiltersQueryParameters));
  };

  const handleClear = () => {
    promptClear(false);
    clearAllFilters();
  };

  const handleSubmitButton = () => {
    clearInsightId();

    if (isExportPage) {
      return handleExport();
    }

    handleSubmit();
  };

  const filters = useVisibleList();
  const enabledFilters = useDisabledFilters(filters.map(fil => fil.name));

  const category1 = enabledFilters.find(
    item => item.name === FILTER_NAMES.CATEGORY_1
  );
  const attributes = enabledFilters.find(
    item => item.name === FILTER_NAMES.ATTRIBUTES
  );
  const company = enabledFilters.find(
    item => item.name === FILTER_NAMES.COMPANY
  );
  const product = enabledFilters.find(
    item => item.name === FILTER_NAMES.PRODUCT
  );
  const promotions = enabledFilters.find(
    item => item.name === FILTER_NAMES.PROMOTIONS
  );
  const brand = enabledFilters.find(item => item.name === FILTER_NAMES.BRAND);
  const subBrand = enabledFilters.find(
    item => item.name === FILTER_NAMES.SUB_BRAND
  );
  const competitionBrand = enabledFilters.find(
    item => item.name === FILTER_NAMES.COMPETITOR_BRAND
  );
  const competitionSubBrand = enabledFilters.find(
    item => item.name === FILTER_NAMES.COMPETITOR_SUB_BRAND
  );
  const referenceBrand = enabledFilters.find(
    item => item.name === FILTER_NAMES.REFERENCE_BRAND
  );
  const referenceSubBrand = enabledFilters.find(
    item => item.name === FILTER_NAMES.REFERENCE_SUB_BRAND
  );
  const voivodeship = enabledFilters.find(
    item => item.name === FILTER_NAMES.VOIVODESHIP
  );
  const county = enabledFilters.find(item => item.name === FILTER_NAMES.COUNTY);
  const shopType = enabledFilters.find(
    item => item.name === FILTER_NAMES.STORE_TYPE
  );
  const shopTypeMeta = enabledFilters.find(
    item => item.name === FILTER_NAMES.STORE_TYPE_META
  );
  const clientType = enabledFilters.find(
    item => item.name === FILTER_NAMES.CLIENT_TYPE
  );
  const delay = enabledFilters.find(item => item.name === FILTER_NAMES.DELAY);
  const competitorCompany = enabledFilters.find(
    item => item.name === FILTER_NAMES.COMPETITOR_COMPANY
  );
  const competitorProduct = enabledFilters.find(
    item => item.name === FILTER_NAMES.COMPETITOR_PRODUCT
  );
  const lfl = enabledFilters.find(item => item.name === FILTER_NAMES.LFL);
  const aggregation = enabledFilters.find(
    item => item.name === FILTER_NAMES.AGGREGATION
  );
  const competitorAggregation = enabledFilters.find(
    item => item.name === FILTER_NAMES.COMPETITOR_AGGREGATION
  );
  const timeAggr = enabledFilters.find(
    item => item.name === FILTER_NAMES.TIME_AGGREGATION
  );
  const date = enabledFilters.find(item => item.name === FILTER_NAMES.DATE);
  const exportDataType = enabledFilters.find(
    item => item.name === FILTER_NAMES.EXPORT_DATA_TYPE
  );
  const referenceCategory = enabledFilters.find(
    item => item.name === FILTER_NAMES.REFERENCE_CATEGORY
  );
  const referenceCategoryAggregation = enabledFilters.find(
    item => item.name === FILTER_NAMES.REFERENCE_CATEGORY_AGGREGATION
  );

  return (
    <form onClick={() => dispatch(setFilterFormPristine(false))}>
      <FiltersSectionToggle sectionKey={FILTER_SECTIONS.MAIN.key}>
        {userTiersAvailable.length > 1 && <TierChoice />}

        {category1 && <CategoryDropdownGroup />}

        {isAttributesEnabled && attributes && (
          <>
            <AttributesDropdown isDisabled={attributes.isDisabled} />
            <AttributesHierarchy isDisabled={attributes.isDisabled} />
          </>
        )}

        {company && (
          <SuperUserCompanies
            isDisabled={company.isDisabled}
            selectedItems={selectedCompany}
            selectedLabel={selectedCompaniesLabel}
          />
        )}

        {(brand || subBrand) && (
          <div id={IDS.BRAND_INPUT}>
            {brand && (
              <BrandDropdown
                id={BrandType.brand}
                key={BrandType.brand}
                label={DROPDOWN_LABELS.BRAND}
                isDisabled={brand.isDisabled}
                isLoading={isLoading.brand}
              />
            )}
            {subBrand && (
              <BrandDropdown
                id={BrandType.subBrand}
                key={BrandType.subBrand}
                label={DROPDOWN_LABELS.SUB_BRAND}
                isDisabled={subBrand.isDisabled}
                isLoading={isLoading.subBrand}
              />
            )}
          </div>
        )}

        {product && (
          <>
            <ProductDropdown
              isDisabled={product.isDisabled}
              selectedLabel={selectedProductsLabel}
            />
            <ProductHierarchy isDisabled={product.isDisabled} />
          </>
        )}

        {date && (
          <DateDropdown
            label={DROPDOWN_LABELS.DATE}
            className={s.filterDateDropdown}
          />
        )}

        {promotions && (
          <PromotionsDropdown
            isDisabled={promotions.isDisabled}
            selectedLabel={selectedPromotionsLabel}
          />
        )}
        {timeAggr && (
          <TimeAggregationDropdown isDisabled={timeAggr.isDisabled} />
        )}
      </FiltersSectionToggle>

      {competitorCompany && competitorProduct && (
        <FiltersSectionToggle sectionKey={FILTER_SECTIONS.COMPETITION.key}>
          {delay && <DelayCompetition isDisabled={delay.isDisabled} />}
          {competitorCompany && (
            <CompetitionCompanyDropdown
              selectedItems={selectedCompetitorCompanies}
              isDisabled={competitorCompany.isDisabled}
            />
          )}
          {competitionBrand && (
            <BrandDropdown
              id={BrandType.competingBrand}
              key={BrandType.competingBrand}
              label={DROPDOWN_LABELS.BRAND}
              isDisabled={competitionBrand.isDisabled}
              isLoading={isLoading.competingBrand}
            />
          )}
          {competitionSubBrand && (
            <BrandDropdown
              id={BrandType.competingSubBrand}
              key={BrandType.competingSubBrand}
              label={DROPDOWN_LABELS.SUB_BRAND}
              isDisabled={competitionSubBrand.isDisabled}
              isLoading={isLoading.competingSubBrand}
            />
          )}
          {competitorProduct && (
            <CompetitionProductDropdown
              selectedItems={selectedCompetitorProducts}
              isDisabled={competitorProduct.isDisabled}
            />
          )}
        </FiltersSectionToggle>
      )}

      {referenceCategory && (
        <div id={IDS.REF_CAT_INPUT}>
          <FiltersSectionToggle
            sectionKey={FILTER_SECTIONS.REFERENCE_CATEGORIES.key}
          >
            <ReferenceCategories
              isDisabled={referenceCategory.isDisabled}
              isAggregationDisabled={
                referenceCategoryAggregation?.isDisabled || false
              }
            />
          </FiltersSectionToggle>
        </div>
      )}

      {referenceBrand && referenceSubBrand && (
        <FiltersSectionToggle sectionKey={FILTER_SECTIONS.REFERENCE_BRANDS.key}>
          <BrandDropdown
            id={BrandType.referenceBrand}
            key={BrandType.referenceBrand}
            label={DROPDOWN_LABELS.BRAND}
            isDisabled={referenceBrand.isDisabled}
            isLoading={isLoading.referenceBrand}
          />
          <BrandDropdown
            id={BrandType.referenceSubBrand}
            key={BrandType.referenceSubBrand}
            label={DROPDOWN_LABELS.SUB_BRAND}
            isDisabled={referenceSubBrand.isDisabled}
            isLoading={isLoading.referenceSubBrand}
          />
          <AggregateReferenceBrands isDisabled={referenceBrand.isDisabled} />
        </FiltersSectionToggle>
      )}

      {(voivodeship || county) && (
        <FiltersSectionToggle sectionKey={FILTER_SECTIONS.REGION.key}>
          {voivodeship && (
            <VoivodeshipDropdown isDisabled={voivodeship.isDisabled} />
          )}
          {county && <CountyDropdown isDisabled={county.isDisabled} />}
        </FiltersSectionToggle>
      )}

      {(shopType || shopTypeMeta) && (
        <FiltersSectionToggle sectionKey={FILTER_SECTIONS.SHOP_TYPE.key}>
          {shopTypeMeta && isMetaSegmentFilterEnabled && (
            <ShopTypeMetaDropdown isDisabled={shopTypeMeta.isDisabled} />
          )}
          {shopType && <ShopTypeDropdown isDisabled={shopType.isDisabled} />}
        </FiltersSectionToggle>
      )}
      {clientType && (
        <div id={IDS.CLIENT_INPUT}>
          <FiltersSectionToggle
            refToScroll={bottomRef}
            sectionKey={FILTER_SECTIONS.CLIENT_TYPE.key}
          >
            <ClientTypeChoiceGroup isDisabled={clientType.isDisabled} />
          </FiltersSectionToggle>
        </div>
      )}
      {lfl && (
        <FiltersSectionToggle
          refToScroll={bottomRef}
          sectionKey={FILTER_SECTIONS.LFL.key}
        >
          <LFLChoiceGroup isDisabled={lfl.isDisabled} />
        </FiltersSectionToggle>
      )}
      {exportDataType && (
        <FiltersSectionToggle
          refToScroll={bottomRef}
          sectionKey={FILTER_SECTIONS.EXPORT_DATA_TYPE.key}
        >
          <ExportDataType isDisabled={exportDataType.isDisabled} />
        </FiltersSectionToggle>
      )}
      <SuperUserSettings />
      <div className={s.buttonsWrapper}>
        {aggregation && (
          <AggregationFilters
            isDisabled={aggregation.isDisabled}
            isCompetitionVisible={!!competitorAggregation}
            isCompetitionDisabled={competitorAggregation?.isDisabled || false}
          />
        )}
        <Button
          className={cn(s.submitButton, {
            [s.withFakeCategoryTooltip]: areSelectedCategoriesFake
          })}
          testId="submit-filters-button"
          onClick={handleSubmitButton}
          type="button"
          disabled={isSubmitButtonDisabled}
          data-fake-category-tooltip="Wybierz podkategorię, aby pobrać dane"
        >
          <Text className={s.submitButtonText}>{getSubmitButtonText()}</Text>
        </Button>
        <Button
          className={s.resetButton}
          testId="clear-all-filters-button"
          onClick={() => {
            promptClear(true);
            pushFiltersEvent("clear all");
          }}
          type="button"
          {...{
            "data-ga-filter-option": "wyczyść wszystkie"
          }}
        >
          <Text className={s.resetButtonText}>
            <Reload size={ICON_SIZES.LARGE} className={s.resetButtonIcon} />
            Wyczyść wszystko
          </Text>
        </Button>
      </div>
      {isClearPrompted && (
        <ConfirmModal
          ga={{
            confirm: {
              "data-ga-filter-option": "tak"
            },
            cancel: {
              "data-ga-filter-option": "nie"
            }
          }}
          onCancel={() => promptClear(false)}
          onConfirm={handleClear}
          position={{ top: 200 }}
          message="Czy jesteś pewien, że chcesz wyczyścić wszystkie filtry?"
          testId="confirm-modal"
        />
      )}
      <div ref={bottomRef} />
    </form>
  );
};
