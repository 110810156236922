import { formatNumber, NUMBER_TYPE } from "pages/Reports/utils/formatNumber";
import { CHART_DATA_TYPE, NO_DATA_SHORT } from "utils";
import { Nullable } from "utils/types";

export const formatTooltipValue = (
  value: Nullable<number>,
  dataType: string
): string => {
  if (value === null) return NO_DATA_SHORT;

  switch (dataType) {
    case CHART_DATA_TYPE.NEW_CUSTOMERS_PERCENT:
    case CHART_DATA_TYPE.NEW_CUSTOMERS_PERCENT_LAST_YEAR:
    case CHART_DATA_TYPE.ALL_RETURNS_PERCENT:
    case CHART_DATA_TYPE.ALL_RETURNS_PERCENT_LAST_YEAR:
    case CHART_DATA_TYPE.RETURNS_1_WEEK_PERCENT:
    case CHART_DATA_TYPE.RETURNS_1_WEEK_PERCENT_LAST_YEAR:
    case CHART_DATA_TYPE.RETURNS_2_WEEKS_PERCENT:
    case CHART_DATA_TYPE.RETURNS_2_WEEKS_PERCENT_LAST_YEAR:
    case CHART_DATA_TYPE.RETURNS_4_WEEKS_PERCENT:
    case CHART_DATA_TYPE.RETURNS_4_WEEKS_PERCENT_LAST_YEAR:
    case CHART_DATA_TYPE.RETURNS_6_WEEKS_PERCENT:
    case CHART_DATA_TYPE.RETURNS_6_WEEKS_PERCENT_LAST_YEAR:
    case CHART_DATA_TYPE.RETURNS_8_WEEKS_PERCENT:
    case CHART_DATA_TYPE.RETURNS_8_WEEKS_PERCENT_LAST_YEAR:
    case CHART_DATA_TYPE.VALUE_SHARES:
    case CHART_DATA_TYPE.VALUE_SHARES_LAST_YEAR:
    case CHART_DATA_TYPE.DISTRIBUTION_RANGE:
    case CHART_DATA_TYPE.DISTRIBUTION_RANGE_LAST_YEAR:
    case CHART_DATA_TYPE.PLANOGRAM_DISTRIBUTION_RANGE:
    case CHART_DATA_TYPE.PLANOGRAM_DISTRIBUTION_RANGE_LAST_YEAR:
    case CHART_DATA_TYPE.QUANTITY_SHARES:
    case CHART_DATA_TYPE.QUANTITY_SHARES_LAST_YEAR:
    case CHART_DATA_TYPE.NET_QUANTITY_SHARE:
    case CHART_DATA_TYPE.NET_QUANTITY_SHARE_LAST_YEAR:
    case CHART_DATA_TYPE.VALUE_DYNAMICS:
    case CHART_DATA_TYPE.VALUE_DYNAMICS_LAST_YEAR:
    case CHART_DATA_TYPE.QUANTITY_DYNAMICS:
    case CHART_DATA_TYPE.QUANTITY_DYNAMICS_LAST_YEAR:
    case CHART_DATA_TYPE.DYNAMICS_NET_QUANTITY_CHANGE:
    case CHART_DATA_TYPE.DYNAMICS_NET_QUANTITY_CHANGE_LAST_YEAR:
    case CHART_DATA_TYPE.SHARE_IN_RECEIPTS_CATEGORY:
    case CHART_DATA_TYPE.SHARE_IN_RECEIPTS_CATEGORY_LAST_YEAR:
    case CHART_DATA_TYPE.SHARE_IN_RECEIPTS_TOTAL_SHOP:
    case CHART_DATA_TYPE.SHARE_IN_RECEIPTS_TOTAL_SHOP_LAST_YEAR:
    case CHART_DATA_TYPE.VALUE_PERCENT:
    case CHART_DATA_TYPE.QUANTITY_SUM_PERCENT:
    case CHART_DATA_TYPE.VALUE_SHARES_WS:
    case CHART_DATA_TYPE.VALUE_SHARES_WS_LAST_YEAR:
    case CHART_DATA_TYPE.VALUE_SHARES_WSO:
    case CHART_DATA_TYPE.VALUE_SHARES_WSO_LAST_YEAR:
    case CHART_DATA_TYPE.VALUE_SHARES_WSA:
    case CHART_DATA_TYPE.VALUE_SHARES_WSA_LAST_YEAR:
    case CHART_DATA_TYPE.VALUE_SHARES_WS_WSO:
    case CHART_DATA_TYPE.VALUE_SHARES_WS_WSO_LAST_YEAR:
    case CHART_DATA_TYPE.VALUE_SHARES_WS_WSO_WSA:
    case CHART_DATA_TYPE.VALUE_SHARES_WS_WSO_WSA_LAST_YEAR:
    case CHART_DATA_TYPE.QUANTITY_SHARES_WS:
    case CHART_DATA_TYPE.QUANTITY_SHARES_WS_LAST_YEAR:
    case CHART_DATA_TYPE.QUANTITY_SHARES_WSO:
    case CHART_DATA_TYPE.QUANTITY_SHARES_WSO_LAST_YEAR:
    case CHART_DATA_TYPE.QUANTITY_SHARES_WSA:
    case CHART_DATA_TYPE.QUANTITY_SHARES_WSA_LAST_YEAR:
    case CHART_DATA_TYPE.QUANTITY_SHARES_WS_WSO:
    case CHART_DATA_TYPE.QUANTITY_SHARES_WS_WSO_LAST_YEAR:
    case CHART_DATA_TYPE.QUANTITY_SHARES_WS_WSO_WSA:
    case CHART_DATA_TYPE.QUANTITY_SHARES_WS_WSO_WSA_LAST_YEAR:
    case CHART_DATA_TYPE.NET_QUANTITY_SHARE_WS:
    case CHART_DATA_TYPE.NET_QUANTITY_SHARE_WS_LAST_YEAR:
    case CHART_DATA_TYPE.NET_QUANTITY_SHARE_WSO:
    case CHART_DATA_TYPE.NET_QUANTITY_SHARE_WSO_LAST_YEAR:
    case CHART_DATA_TYPE.NET_QUANTITY_SHARE_WSA:
    case CHART_DATA_TYPE.NET_QUANTITY_SHARE_WSA_LAST_YEAR:
    case CHART_DATA_TYPE.NET_QUANTITY_SHARE_WS_WSO:
    case CHART_DATA_TYPE.NET_QUANTITY_SHARE_WS_WSO_LAST_YEAR:
    case CHART_DATA_TYPE.NET_QUANTITY_SHARE_WS_WSO_WSA:
    case CHART_DATA_TYPE.NET_QUANTITY_SHARE_WS_WSO_WSA_LAST_YEAR:
      return formatNumber(value, NUMBER_TYPE.PERCENT);

    case CHART_DATA_TYPE.NEW_CUSTOMERS_COUNT:
    case CHART_DATA_TYPE.ALL_RETURNS_COUNT:
    case CHART_DATA_TYPE.RETURNS_1_WEEK_ABSOLUTE:
    case CHART_DATA_TYPE.RETURNS_1_WEEK_ABSOLUTE_LAST_YEAR:
    case CHART_DATA_TYPE.RETURNS_2_WEEKS_ABSOLUTE:
    case CHART_DATA_TYPE.RETURNS_2_WEEKS_ABSOLUTE_LAST_YEAR:
    case CHART_DATA_TYPE.RETURNS_4_WEEKS_ABSOLUTE:
    case CHART_DATA_TYPE.RETURNS_4_WEEKS_ABSOLUTE_LAST_YEAR:
    case CHART_DATA_TYPE.RETURNS_6_WEEKS_ABSOLUTE:
    case CHART_DATA_TYPE.RETURNS_6_WEEKS_ABSOLUTE_LAST_YEAR:
    case CHART_DATA_TYPE.RETURNS_8_WEEKS_ABSOLUTE:
    case CHART_DATA_TYPE.RETURNS_8_WEEKS_ABSOLUTE_LAST_YEAR:
    case CHART_DATA_TYPE.RECEIPTS_PER_SHOP:
    case CHART_DATA_TYPE.RECEIPTS_QUANTITY:
    case CHART_DATA_TYPE.ITEMS_PER_RECEIPT:
    case CHART_DATA_TYPE.VALUE_PER_SHOP:
    case CHART_DATA_TYPE.WORTH_PER_RECEIPT:
    case CHART_DATA_TYPE.AVG_PRICE:
    case CHART_DATA_TYPE.TOTAL_SUPPLY:
    case CHART_DATA_TYPE.SHOPS_SUPPLY:
    case CHART_DATA_TYPE.INCOMING_SUPPLY:
    case CHART_DATA_TYPE.CL_TOTAL_SUPPLY:
    case CHART_DATA_TYPE.BED_SUPPLY:
    case CHART_DATA_TYPE.NAD_SUPPLY:
    case CHART_DATA_TYPE.PLE_SUPPLY:
    case CHART_DATA_TYPE.SZA_SUPPLY:
    case CHART_DATA_TYPE.TYCH_SUPPLY:
    case CHART_DATA_TYPE.TYN_SUPPLY:
    case CHART_DATA_TYPE.RADZ_SUPPLY:
    case CHART_DATA_TYPE.KM_SUPPLY:
    case CHART_DATA_TYPE.WPZ_SHOPS_7:
    case CHART_DATA_TYPE.WPZ_CL_TOTAL_7:
    case CHART_DATA_TYPE.WPZ_BED_7:
    case CHART_DATA_TYPE.WPZ_NAD_7:
    case CHART_DATA_TYPE.WPZ_PLE_7:
    case CHART_DATA_TYPE.WPZ_SZA_7:
    case CHART_DATA_TYPE.WPZ_TYCH_7:
    case CHART_DATA_TYPE.WPZ_TYN_7:
    case CHART_DATA_TYPE.WPZ_RADZ_7:
    case CHART_DATA_TYPE.WPZ_KM_7:
    case CHART_DATA_TYPE.WPZ_SHOPS_30:
    case CHART_DATA_TYPE.WPZ_CL_TOTAL_30:
    case CHART_DATA_TYPE.WPZ_BED_30:
    case CHART_DATA_TYPE.WPZ_NAD_30:
    case CHART_DATA_TYPE.WPZ_PLE_30:
    case CHART_DATA_TYPE.WPZ_SZA_30:
    case CHART_DATA_TYPE.WPZ_TYCH_30:
    case CHART_DATA_TYPE.WPZ_TYN_30:
    case CHART_DATA_TYPE.WPZ_RADZ_30:
    case CHART_DATA_TYPE.WPZ_KM_30:
    case CHART_DATA_TYPE.WPZ_SHOPS_90:
    case CHART_DATA_TYPE.WPZ_CL_TOTAL_90:
    case CHART_DATA_TYPE.WPZ_BED_90:
    case CHART_DATA_TYPE.WPZ_NAD_90:
    case CHART_DATA_TYPE.WPZ_PLE_90:
    case CHART_DATA_TYPE.WPZ_SZA_90:
    case CHART_DATA_TYPE.WPZ_TYCH_90:
    case CHART_DATA_TYPE.WPZ_TYN_90:
    case CHART_DATA_TYPE.WPZ_RADZ_90:
    case CHART_DATA_TYPE.WPZ_KM_90:
      return formatNumber(value, NUMBER_TYPE.GROUPED_ABSOLUTE_VALUE);

    default:
      return formatNumber(value);
  }
};
