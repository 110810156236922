import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { RadioButton, Text, TierValidator } from "components/atoms";
import { useFiltersQP } from "hooks";
import {
  EXPORT_DATA_TYPE,
  exportDataTypeSelector,
  ExportDataTypeState,
  updateExportDataType
} from "pages/Reports/redux/reducers/filters/exportDataTypeReducer";
import { useExportDataTypeDisabledFilter } from "pages/Reports/redux/reducers/filters/hooks/useDisabledFilters";

import { DROPDOWN_LABELS } from "../constants";
import s from "./exportDataType.module.scss";

const {
  GENERAL,
  SEGMENTS,
  COUNTIES,
  VOIVODESHIPS,
  SHOPS,
  HOURS
} = EXPORT_DATA_TYPE;

type Props = { isDisabled: boolean };

export const ExportDataType = ({ isDisabled }: Props) => {
  const dispatch = useDispatch();
  const currentExportDataType = useSelector(exportDataTypeSelector);
  const disabledTypes = useExportDataTypeDisabledFilter();
  const { exportDataTypeQP } = useFiltersQP();

  const handleChange = (exportDataType: ExportDataTypeState) => {
    if (isDisabled) return;

    dispatch(updateExportDataType(exportDataType));
  };

  useEffect(() => {
    dispatch(updateExportDataType(exportDataTypeQP));
  }, [dispatch, exportDataTypeQP]);

  const EXPORT_DATA_BUTTONS = [
    {
      label: "Ogólne",
      exportType: GENERAL,
      tiers: [1, 2, 3, 4]
    },
    {
      label: "Wg segmentów",
      exportType: SEGMENTS,
      tiers: [1, 2, 3, 4]
    },
    {
      label: "Wg godzin",
      exportType: HOURS,
      tiers: [3, 4]
    },
    {
      label: "Wg województw",
      exportType: VOIVODESHIPS,
      tiers: [3, 4]
    },
    {
      label: "Wg powiatów",
      exportType: COUNTIES,
      tiers: [3, 4]
    },
    {
      label: "Wg sklepów",
      exportType: SHOPS,
      tiers: [4]
    }
  ].map(btn => {
    const disabled = disabledTypes.find(({ type }) => type === btn.exportType);

    return {
      ...btn,
      isDisabled: disabled ? true : isDisabled,
      tooltipText: disabled ? disabled.tooltip : ""
    };
  });

  return (
    <div className={s.base} data-testid="export-data-type-wrapper">
      <Text className={s.title}>{DROPDOWN_LABELS.EXPORT_DATA_TYPE}</Text>
      {EXPORT_DATA_BUTTONS.map(btn => (
        <TierValidator key={btn.label} accessLevels={btn.tiers}>
          <RadioButton
            className={{ base: s.radioBase }}
            label={btn.label}
            isDisabled={btn.isDisabled}
            isChecked={currentExportDataType === btn.exportType}
            onChange={() => handleChange(btn.exportType)}
            tooltipText={btn.tooltipText}
          />
        </TierValidator>
      ))}
    </div>
  );
};
