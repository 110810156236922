import { combineReducers } from "redux";

import { dynamicFiltersQueryReducer } from "pages/Reports/redux/reducers/asyncFiltersReducer";
import { chartReducer } from "pages/Reports/redux/reducers/chartReducer";
import { dynamicsReportReducer } from "pages/Reports/sections/Dynamics/redux/reducers/dynamicsReportReducer";
import { exportReducer } from "pages/Reports/sections/Export/redux/reducers/exportReducer";
import { locationReducer } from "pages/Reports/sections/Location/redux/";
import { logisticsReportReducer } from "pages/Reports/sections/Logistics/redux/reducers/logisticsReportReducer";
import { receiptsReportReducer } from "pages/Reports/sections/Receipts/redux/reducers/receiptsReportReducer";
import { segmentsChartReducer } from "pages/Reports/sections/Segments/redux/reducers/segmentsChartReducer";
import { sharesReportReducer } from "pages/Reports/sections/Shares/redux/reducers/sharesReportReducer";

import { sellReducer } from "../sections/Sell/redux";
import { competitionReportsFiltersReducer } from "./reducers/filters/competition/competitionFiltersReducer";
import { reportsFiltersReducer } from "./reducers/filters/filtersReducer";
import { promotionsReducer } from "./reducers/promotionsReducer";

export const reportsReducer = combineReducers({
  asyncFilters: dynamicFiltersQueryReducer,
  sell: sellReducer,
  location: locationReducer,
  v2Dynamics: combineReducers({
    report: dynamicsReportReducer
  }),
  v2Shares: combineReducers({
    report: sharesReportReducer
  }),
  receipts: combineReducers({
    report: receiptsReportReducer
  }),
  logistics: combineReducers({
    report: logisticsReportReducer
  }),
  export: exportReducer,
  promotions: promotionsReducer,
  chart: chartReducer,
  segments: combineReducers({
    chart: segmentsChartReducer
  }),
  filters: reportsFiltersReducer,
  competitionFilters: competitionReportsFiltersReducer
});
