import { createSelector } from "reselect";

import { AppState } from "store";
import { RESET_DATA, resetData } from "store/actions/appActions";
import { Keys, Nullable, Thunk, Values } from "utils/types";

type AsyncFiltersReducer = {
  isFormPristine: boolean;
  query: {
    category1: string;
    category2: string;
    category3: string;
    products: string;
    company: Nullable<string>;
    competitorCompanies: string;
    competitorProducts: string;
    shopTypes: Nullable<string>;
    shopTypesMeta: Nullable<string>;
    counties: Nullable<string>;
    referenceCategory: string;
    brand: string;
    subBrand: string;
    competingBrand: string;
    competingSubBrand: string;
    referenceBrand: string;
    referenceSubBrand: string;
    promotions: string;
    attributes: string;
  };
  fetching: {
    category1: boolean;
    category2: boolean;
    category3: boolean;
    products: boolean;
    company: boolean;
    competitorCompanies: boolean;
    competitorProducts: boolean;
    shopTypes: boolean;
    shopTypesMeta: boolean;
    counties: boolean;
    referenceCategory: boolean;
    brand: boolean;
    subBrand: boolean;
    competingBrand: boolean;
    competingSubBrand: boolean;
    referenceBrand: boolean;
    referenceSubBrand: boolean;
    promotions: boolean;
    attributes: boolean;
  };
  error: {
    category1: string;
    category2: string;
    category3: string;
    products: string;
    company: string;
    competitorCompanies: string;
    competitorProducts: string;
    shopTypes: string;
    shopTypesMeta: string;
    counties: string;
    referenceCategory: string;
    brand: string;
    subBrand: string;
    competingBrand: string;
    competingSubBrand: string;
    referenceBrand: string;
    referenceSubBrand: string;
    promotions: string;
    attributes: string;
  };
};

export const initialState: AsyncFiltersReducer = {
  isFormPristine: true,
  query: {
    category1: "",
    category2: "",
    category3: "",
    products: "",
    company: null,
    competitorCompanies: "",
    competitorProducts: "",
    shopTypes: null,
    shopTypesMeta: null,
    counties: null,
    referenceCategory: "",
    brand: "",
    subBrand: "",
    competingBrand: "",
    competingSubBrand: "",
    referenceBrand: "",
    referenceSubBrand: "",
    promotions: "",
    attributes: ""
  },
  fetching: {
    category1: false,
    category2: false,
    category3: false,
    products: false,
    company: false,
    competitorCompanies: false,
    competitorProducts: false,
    shopTypes: false,
    shopTypesMeta: false,
    counties: false,
    referenceCategory: false,
    brand: false,
    subBrand: false,
    competingBrand: false,
    competingSubBrand: false,
    referenceBrand: false,
    referenceSubBrand: false,
    promotions: false,
    attributes: false
  },
  error: {
    category1: "",
    category2: "",
    category3: "",
    products: "",
    company: "",
    competitorCompanies: "",
    competitorProducts: "",
    shopTypes: "",
    shopTypesMeta: "",
    counties: "",
    referenceCategory: "",
    brand: "",
    subBrand: "",
    competingBrand: "",
    competingSubBrand: "",
    referenceBrand: "",
    referenceSubBrand: "",
    promotions: "",
    attributes: ""
  }
};

const SET_PRISTINE = "Filters.SET_PRISTINE" as const;
const UPDATE_FILTER_QUERY = "Filters.UPDATE_FILTER_QUERY" as const;
const SET_FILTER_FETCHING_FLAG = "Filters.SET_FILTER_FETCHING_FLAG" as const;
const SET_FILTER_ERROR = "Filters.SET_FILTER_ERROR" as const;

type SetPristineFormAction = {
  type: typeof SET_PRISTINE;
  payload: boolean;
};

type UpdateDynamicFiltersQueryAction = {
  type: typeof UPDATE_FILTER_QUERY;
  payload: {
    filter: Keys<typeof initialState.query>;
    query: Nullable<string>;
  };
};

type SetFetchingAction = {
  type: typeof SET_FILTER_FETCHING_FLAG;
  payload: {
    filter: Keys<typeof initialState.fetching>;
    isFetching: string;
  };
};

type SetFilterErrorAction = {
  type: typeof SET_FILTER_ERROR;
  payload: {
    filter: Keys<typeof initialState.error>;
    status: string;
  };
};

type FilterActions = {
  SET_PRISTINE: SetPristineFormAction;
  UPDATE_FILTER_QUERY: UpdateDynamicFiltersQueryAction;
  SET_FILTER_FETCHING_FLAG: SetFetchingAction;
  SET_FILTER_ERROR: SetFilterErrorAction;
};

export const asyncFiltersSelector = (state: AppState) =>
  state.reports.asyncFilters;

export const areFiltersReadySelector = createSelector(
  asyncFiltersSelector,
  asyncFilters => Object.values(asyncFilters.fetching).every(value => !value)
);

export const setFilterFormPristine = (payload: boolean) => ({
  type: SET_PRISTINE,
  payload
});

export const updateAsyncFilterQuery = (payload: {
  filter: Keys<typeof initialState.query>;
  query: Nullable<string>;
}) => ({
  type: UPDATE_FILTER_QUERY,
  payload
});

export const setAsyncFilterFetchingFlag = (payload: {
  filter: Keys<typeof initialState.fetching>;
  isFetching: boolean;
}) => ({
  type: SET_FILTER_FETCHING_FLAG,
  payload
});

export const setAsyncFilterFetchingError = (payload: {
  filter: Keys<typeof initialState.error>;
  status: string;
}) => ({
  type: SET_FILTER_ERROR,
  payload
});

export const resetQuery = (): Thunk<Values<FilterActions>> => dispatch => {
  dispatch(updateAsyncFilterQuery({ filter: "category1", query: "" }));
  dispatch(updateAsyncFilterQuery({ filter: "category2", query: "" }));
  dispatch(updateAsyncFilterQuery({ filter: "category3", query: "" }));
  dispatch(updateAsyncFilterQuery({ filter: "products", query: "" }));
  dispatch(
    updateAsyncFilterQuery({ filter: "competitorCompanies", query: "" })
  );
  dispatch(updateAsyncFilterQuery({ filter: "competitorProducts", query: "" }));
  dispatch(updateAsyncFilterQuery({ filter: "counties", query: null }));
  dispatch(updateAsyncFilterQuery({ filter: "company", query: null }));
  dispatch(updateAsyncFilterQuery({ filter: "brand", query: null }));
  dispatch(updateAsyncFilterQuery({ filter: "subBrand", query: null }));
  dispatch(updateAsyncFilterQuery({ filter: "competingBrand", query: null }));
  dispatch(
    updateAsyncFilterQuery({ filter: "competingSubBrand", query: null })
  );
  dispatch(updateAsyncFilterQuery({ filter: "referenceBrand", query: null }));
  dispatch(
    updateAsyncFilterQuery({ filter: "referenceSubBrand", query: null })
  );
  dispatch(updateAsyncFilterQuery({ filter: "shopTypes", query: null }));
  dispatch(updateAsyncFilterQuery({ filter: "shopTypesMeta", query: null }));
  dispatch(updateAsyncFilterQuery({ filter: "referenceCategory", query: "" }));
  dispatch(updateAsyncFilterQuery({ filter: "promotions", query: "" }));
};

export const dynamicFiltersQueryReducer = (
  state: AsyncFiltersReducer = initialState,
  action: Values<FilterActions> | ReturnType<typeof resetData>
): AsyncFiltersReducer => {
  switch (action.type) {
    case SET_PRISTINE:
      return { ...state, isFormPristine: action.payload };
    case UPDATE_FILTER_QUERY:
      return {
        ...state,
        query: { ...state.query, [action.payload.filter]: action.payload.query }
      };
    case SET_FILTER_FETCHING_FLAG:
      return {
        ...state,
        fetching: {
          ...state.fetching,
          [action.payload.filter]: action.payload.isFetching
        }
      };
    case SET_FILTER_ERROR:
      return {
        ...state,
        error: {
          ...state.error,
          [action.payload.filter]: action.payload.status
        }
      };
    case RESET_DATA:
      return initialState;
    default:
      return state;
  }
};
