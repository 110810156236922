import { useMemo, useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import { useSelector } from "react-redux";

import cn from "classnames";
import { groupBy } from "ramda";

import { NiceScrollbar } from "components/atoms";
import { ChevronRight } from "components/atoms/Icon";
import { Checkbox } from "components/molecules";
import {
  AttributesHooks,
  AttributesItemApi
} from "pages/Reports/redux/reducers/sweetStateHooks/useAttributes";
import { isPowerUserSelector } from "store/reducers/userReducer";
import { pushFiltersEvent } from "utils/googleTagManager/dataLayer";

import s from "./attributes.module.scss";

export const AttributesMenu = ({ onClose }: { onClose: VoidFunction }) => {
  const [state, actions] = AttributesHooks.useAttributes();
  const [openSections, setOpenSections] = useState<string[]>([]);
  const isPowerUser = useSelector(isPowerUserSelector);

  const groups = useMemo(() => {
    const byId = groupBy<AttributesItemApi>(attribute =>
      String(attribute.attribute_id)
    )(state.all);
    return Object.keys(byId).map(id => {
      return {
        id: String(byId[id][0].attribute_id),
        name: byId[id][0].attribute_name,
        items: byId[id].map(attribute => {
          const count = isPowerUser
            ? `(${attribute.all_products_count})`
            : `(${attribute.own_products_count}) (${attribute.comp_products_count})`;
          return {
            id: String(attribute.attribute_value_id),
            name: `${attribute.attribute_value_name} ${count}`,
            selected: state.selected.includes(
              String(attribute.attribute_value_id)
            )
          };
        })
      };
    });
  }, [isPowerUser, state.all, state.selected]);
  const selectedGroupsCount = groups.filter(group =>
    group.items.some(item => item.selected)
  ).length;

  return (
    <OutsideClickHandler onOutsideClick={onClose}>
      <div className={s.menu}>
        <NiceScrollbar autoHeight autoHeightMax={300} hideVertical>
          <ul>
            {groups.map(group => {
              const isOpen = openSections.includes(group.id);
              const selectedItems = group.items.filter(item => item.selected);
              const isChecked = selectedItems.length > 0;
              const isDisabled = !isChecked && selectedGroupsCount > 1;
              return (
                <li key={group.id}>
                  <div className={s.attributeWrapper}>
                    <button
                      className={cn(s.chevronButton, {
                        [s.open]: isOpen
                      })}
                      onClick={() => {
                        setOpenSections(
                          isOpen
                            ? openSections.filter(id => id !== group.id)
                            : [...openSections, group.id]
                        );
                      }}
                    >
                      <ChevronRight />
                    </button>
                    <Checkbox
                      className={{
                        base: s.checkboxBase,
                        checkbox: s.checkboxInput
                      }}
                      label={`${group.name} (${selectedItems.length}/${group.items.length})`}
                      isDisabled={isDisabled}
                      tooltipText={
                        isDisabled
                          ? "Możesz wybrać maksymalnie dwie grupy atrybutów"
                          : undefined
                      }
                      isChecked={isChecked}
                      onChange={() => {
                        if (!isChecked) {
                          pushFiltersEvent("attributes");
                        }
                        if (!isChecked && !isOpen) {
                          setOpenSections([...openSections, group.id]);
                        }
                        actions.updateSelectedAttributes(
                          isChecked
                            ? state.selected.filter(id =>
                                group.items.every(item => id !== item.id)
                              )
                            : [
                                ...state.selected,
                                ...group.items.map(item => item.id)
                              ]
                        );
                      }}
                    />
                  </div>
                  {isOpen && (
                    <ul>
                      {group.items.map(item => {
                        return (
                          <li key={item.id} className={s.attributeSubItem}>
                            <Checkbox
                              className={{
                                base: s.checkboxBase,
                                checkbox: s.checkboxInput
                              }}
                              label={item.name}
                              isDisabled={isDisabled}
                              isChecked={item.selected}
                              onChange={() => {
                                if (!item.selected) {
                                  pushFiltersEvent("attributes");
                                }
                                actions.updateSelectedAttributes(
                                  item.selected
                                    ? state.selected.filter(
                                        id => id !== item.id
                                      )
                                    : [...state.selected, item.id]
                                );
                              }}
                            />
                          </li>
                        );
                      })}
                    </ul>
                  )}
                </li>
              );
            })}
          </ul>
        </NiceScrollbar>
      </div>
    </OutsideClickHandler>
  );
};
