import memoize from "memoize-one";
import { createSelector } from "reselect";

import { reportsSelector } from "pages/Reports/redux/selectors/reportsSelectors";
import { reportExportQueryByPeriodSelector } from "store/selectors/routerSelectors";

const sharesReportSelector = createSelector(
  reportsSelector,
  reports => reports.v2Shares.report
);

export const sharesReportLoadingSelector = createSelector(
  sharesReportSelector,
  report => report.isFetching
);

const sharesReportsUrlsSelector = createSelector(
  sharesReportSelector,
  ({ urls }) => urls
);

export const sharesReportUrlByPeriodSelector = createSelector(
  sharesReportsUrlsSelector,
  reportExportQueryByPeriodSelector,
  (sharesReportsUrls, reportExportQueryByPeriod) =>
    memoize(
      (period: string) =>
        sharesReportsUrls[reportExportQueryByPeriod(period)] || ""
    )
);
