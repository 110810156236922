import { combineReducers } from "redux";

import { locationReportReducer } from "pages/Reports/sections/Location/redux/reducers/locationReportReducer";

import { locationChartReducer } from "./reducers/locationChartReducer";

export const locationReducer = combineReducers({
  report: locationReportReducer,
  chart: locationChartReducer
});
