import { RESET_DATA, resetData } from "store/actions/appActions";
import { Values } from "utils/types";

import {
  SET_SELL_REPORT_LOADING,
  UPDATE_SELL_REPORTS_URLS
} from "../actions/reportActions";
import { SellReportsActions, SellReportState } from "../types/reportTypes";

const initialState: SellReportState = {
  isFetching: false,
  urls: {}
};

export const reportReducer = (
  state: SellReportState = initialState,
  action: Values<SellReportsActions> | ReturnType<typeof resetData>
): SellReportState => {
  switch (action.type) {
    case SET_SELL_REPORT_LOADING: {
      return {
        ...state,
        isFetching: action.payload
      };
    }
    case UPDATE_SELL_REPORTS_URLS:
      return {
        ...state,
        urls: {
          ...state.urls,
          ...action.payload
        }
      };

    case RESET_DATA:
      return initialState;

    default:
      return state;
  }
};
