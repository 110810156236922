import { formatNumber } from "pages/Reports/utils/formatNumber";
import { NUMBER_TYPE } from "pages/Reports/utils/formatNumber";
import { NO_DATA_SHORT } from "utils";
import { Nullable } from "utils/types";

export const formatSummaryNumber = (
  value: Nullable<number | string>,
  specifier: string
) => {
  if (specifier === NUMBER_TYPE.NOT_APPLICABLE) return "nd";
  if (value === null) return NO_DATA_SHORT;
  if (specifier === NUMBER_TYPE.RAW || typeof value === "string") {
    return String(value);
  }
  return formatNumber(value, specifier);
};
