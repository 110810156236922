import React from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  asyncFiltersSelector,
  initialState
} from "pages/Reports/redux/reducers/asyncFiltersReducer";
import {
  checkCompetitorCompaniesPristineSelectionsAfterFetching,
  checkCompetitorCompaniesSelectionsAfterFetching,
  sortAllCompetitionCompaniesBySelectionOrder,
  updateAllCompetitionCompanies
} from "pages/Reports/redux/reducers/filters/competition/competitionCompaniesFilterReducer";
import {
  checkCompetitionProductsSelectionsAfterFetching,
  checkCompetitorProductsPristineSelectionsAfterFetching,
  sortCompetitionProductsBySelectionOrder,
  updateAllCompetitionProducts
} from "pages/Reports/redux/reducers/filters/competition/competitionProductsFilterReducer";
import { Keys } from "utils/types";

type Config = {
  [key in Keys<typeof initialState.query>]: {
    save: any;
    selectItemsForPristineForm: any;
    selectItemsForNotPristineForm: any;
    sort: any;
  };
};

// referenceCategory has different callback
// category does not have fetching effect
// @ts-ignore
const config: Omit<Config, "category" | "referenceCategory"> = {
  competitorCompanies: {
    save: updateAllCompetitionCompanies,
    selectItemsForPristineForm: checkCompetitorCompaniesPristineSelectionsAfterFetching,
    selectItemsForNotPristineForm: checkCompetitorCompaniesSelectionsAfterFetching,
    sort: sortAllCompetitionCompaniesBySelectionOrder
  },
  competitorProducts: {
    save: updateAllCompetitionProducts,
    selectItemsForPristineForm: checkCompetitorProductsPristineSelectionsAfterFetching,
    selectItemsForNotPristineForm: checkCompetitionProductsSelectionsAfterFetching,
    sort: sortCompetitionProductsBySelectionOrder
  }
};

export function useFetchingCallback<T extends { value: number | string }>(
  filter: Keys<typeof config>
) {
  const isPristine = useSelector(asyncFiltersSelector).isFormPristine;
  const dispatch = useDispatch();

  const {
    save,
    selectItemsForPristineForm,
    selectItemsForNotPristineForm,
    sort
  } = config[filter];

  const saveAndSelectCallback = React.useCallback(
    (items: T[]) => {
      dispatch(save(items));
      if (isPristine) {
        dispatch(selectItemsForPristineForm());
      }
      dispatch(selectItemsForNotPristineForm(items.map(item => item.value)));
      dispatch(sort());
    },
    [
      dispatch,
      isPristine,
      save,
      selectItemsForNotPristineForm,
      selectItemsForPristineForm,
      sort
    ]
  );
  return saveAndSelectCallback;
}
