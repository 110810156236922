import { format } from "date-fns";

import { EXPORT_DATA_TYPE } from "pages/Reports/redux/reducers/filters/exportDataTypeReducer";
import {
  ExportTableData,
  ExportTableDataApi,
  ExportTableDataApiSplit
} from "pages/Reports/sections/Export/redux/types";
import {
  BOOL_STRING_VALUES,
  CLIENT_TYPES,
  DATE_TIME_SECONDS_FORMAT
} from "utils";
import { Timeline } from "utils/types";

import { getFirstValidArray } from "./getFirstValidArray";
import { getQueryParams } from "./getQueryParams";
import {
  translateClientType,
  translateDataType,
  translateTimeAggregation
} from "./translateParams";
import { ExportBody, ExportInputParams } from "./types";

const { GENERAL, SEGMENTS, VOIVODESHIPS, COUNTIES } = EXPORT_DATA_TYPE;
const { TRUE } = BOOL_STRING_VALUES;

export const mapExportBodyForBE = (params: ExportInputParams) => {
  const body: Partial<ExportBody> = {};

  if (params.date_from) {
    body["date_from"] = params.date_from;
  }

  if (params.date_to) {
    body["date_to"] = params.date_to;
  }

  if (params.compare_date_from) {
    body["compare_date_from"] = params.compare_date_from;
  }

  if (params.compare_date_to) {
    body["compare_date_to"] = params.compare_date_to;
  }

  if (params.include_leap_year_day) {
    body["include_leap_year_day"] = params.include_leap_year_day === TRUE;
  }

  if (params.categories) {
    body["categories"] = params.categories.split(",").map(id => Number(id));
  }

  if (params.category_level) {
    body["category_level"] = Number(params.category_level);
  }

  if (params.timeline_unit) {
    body["timeline_unit"] = params.timeline_unit as Partial<Timeline>;
  }

  if (params.materials) {
    body["materials"] = params.materials.split(",").map(id => Number(id));
  }

  if (params.vendor_id?.length) {
    body["vendor_id"] = params.vendor_id[0];
  }

  if (params.companies?.length) {
    delete body.vendor_id; // in case `companies` param is provided `vendor_id` has to be deleted
    body["companies"] = params.companies;
  }

  if (params.aggregation) {
    body["aggregation"] = params.aggregation;
  }

  if (params.lfl) {
    body["lfl"] = params.lfl === TRUE;
  }

  if (params.voivodeships) {
    body["voivodeships"] = params.voivodeships;
  }

  if (params.counties) {
    body["counties"] = params.counties;
  }

  if (params.segments) {
    body["segments"] = params.segments.split(",");
  }

  // BE doesn't accept "general" type
  if (params.split_types && params.split_types !== GENERAL) {
    const isSegments = params.split_types === SEGMENTS;
    const isRegion =
      params.split_types === VOIVODESHIPS || params.split_types === COUNTIES;

    // delete region in case segments type is selected
    if (isSegments) {
      delete body.voivodeships;
      delete body.counties;
    }

    // delete segments in case region type is selected
    if (isRegion) {
      delete body.segments;
    }

    body["split_types"] = [params.split_types];
  }

  // BE doesn't accept "all" type
  if (params.client_type && params.client_type !== CLIENT_TYPES.ALL) {
    body["client_type"] = params.client_type;
  }

  if (params.reference_categories) {
    body["reference_categories"] = params.reference_categories
      .split(",")
      .map(id => Number(id));
  }

  if (params.reference_category_level) {
    body["reference_category_level"] = Number(params.reference_category_level);
  }

  if (params.brands) {
    body["brands"] = params.brands.split(",");
  }

  if (params.sub_brands) {
    body["sub_brands"] = params.sub_brands.split(",");
  }

  if (params.excluded_days) {
    body["excluded_days"] = params.excluded_days
      .split(",")
      .filter(day => day !== "");
  }

  return body;
};

const transformExportData = (data: ExportTableDataApi[], isArchived: boolean) =>
  data.map(
    (datum): ExportTableData => ({
      date: format(new Date(datum.created_at), DATE_TIME_SECONDS_FORMAT),
      dateFrom: datum.date_from,
      dateTo: datum.date_to,
      compareDateFrom: datum.compare_date_from || "",
      compareDateTo: datum.compare_date_to || "",
      vendorNames: datum.vendor_names || [],
      categories: datum.objects_names,
      segments: getFirstValidArray(
        datum.segments_names,
        datum.voivodeships,
        datum.counties
      ),
      dataType: translateDataType(
        datum.split_types === null ? GENERAL : datum.split_types[0]
      ),
      timelineUnit: translateTimeAggregation(datum.timeline_unit),
      clientType: translateClientType(datum.client_type),
      lfl: datum.lfl,
      url: datum.url,
      params: getQueryParams(datum),
      id: datum.id,
      status: datum.status,
      isArchived,
      excludedDays: datum.excluded_days || [],
      brands: datum.brands || [],
      subBrands: datum.sub_brands || [],
      referenceObjectsNames: datum.reference_objects_names || []
    })
  );

export const normalizeExportTableData = (data: ExportTableDataApiSplit) => ({
  unarchived: transformExportData(data.unarchived, false),
  archived: transformExportData(data.archived, true)
});
