import { CallHistoryMethodAction } from "connected-react-router";
import { ParsedQuery } from "query-string";
import { toLower } from "ramda";

import { DropdownType } from "components/molecules/ChartHeader/hooks/useDropdownTypes";
import { ChartDataTypes } from "pages/Reports/types/chart";
import { updateQueryParams } from "store/actions/routerActions";
import {
  queryParamsSelector,
  uppercasedPromotionChartDataTypeSelector
} from "store/selectors/routerSelectors";
import { toArray } from "store/utils";
import { CHART_DATA_TYPE } from "utils";
import { QP } from "utils/defaultQueryParams";
import { pushChartMetricsEvent } from "utils/googleTagManager/dataLayer";
import { Thunk, Values } from "utils/types";

const getNewParams = (
  type: string,
  dropdown: DropdownType,
  param: Values<ParsedQuery<string>>
) => {
  const value = toLower(type);

  switch (dropdown) {
    case "radio":
    case "checkbox-single":
      return param === value ? CHART_DATA_TYPE.NONE : value;
    case "checkbox-multi":
      const params: string[] = toArray(param).map(toLower);

      return params.includes(value)
        ? params.filter(param => param !== value)
        : [...params, value];
    default:
      return "";
  }
};

export const changeMainChartDataType = (
  type: string,
  dropdown: DropdownType
): Thunk<CallHistoryMethodAction> => (dispatch, getState) => {
  const state = getState();
  const { chart_data_type_first, chart_data_type_second } = queryParamsSelector(
    state
  );

  const params = getNewParams(type, dropdown, chart_data_type_first);

  pushChartMetricsEvent(String(params), String(chart_data_type_second));

  dispatch(updateQueryParams({ [QP.CHART_DATA_TYPE_FIRST]: params }));
};

export const changeSecondChartDataType = (
  type: string,
  dropdown: DropdownType
): Thunk<CallHistoryMethodAction> => {
  return (dispatch, getState) => {
    const state = getState();
    const {
      chart_data_type_first,
      chart_data_type_second
    } = queryParamsSelector(state);

    const params = getNewParams(type, dropdown, chart_data_type_second);

    pushChartMetricsEvent(String(chart_data_type_first), String(params));

    dispatch(updateQueryParams({ [QP.CHART_DATA_TYPE_SECOND]: params }));
  };
};

export const changePromotionChartDataType = (
  type: ChartDataTypes,
  dropdown: DropdownType
): Thunk<CallHistoryMethodAction> => (dispatch, getState) => {
  const state = getState();
  const selectedPromotionDataTypes = uppercasedPromotionChartDataTypeSelector(
    state
  );
  const params = getNewParams(type, dropdown, selectedPromotionDataTypes);

  dispatch(updateQueryParams({ [QP.PROMOTIONS_CHART_DATA_TYPE]: params }));
};
