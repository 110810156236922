import { ParsedQuery } from "query-string";

import { ExportTableDataApi } from "../redux/types";

export const getQueryParams = (data: ExportTableDataApi): ParsedQuery => {
  const {
    aggregation,
    category_1,
    category_2,
    category_3,
    reference_category_1,
    reference_category_2,
    reference_category_3,
    client_type,
    counties,
    date_from,
    date_to,
    compare_date_from,
    compare_date_to,
    split_types,
    lfl,
    materials,
    segments,
    timeline_unit,
    vendor_id,
    companies,
    voivodeships,
    brands,
    sub_brands
  } = data;
  const hasMultipleCompanies = vendor_id === "" && Boolean(companies);

  const params = {
    aggregated: String(aggregation),
    category1: category_1 ? String(category_1) : "",
    category2: category_2 ? category_2.join(",") : "",
    category3: category_3 ? category_3.join(",") : "",
    ref_cat1: reference_category_1 ? String(reference_category_1) : "",
    ref_cat2: reference_category_2 ? reference_category_2.join(",") : "",
    ref_cat3: reference_category_3 ? reference_category_3.join(",") : "",
    client: client_type,
    counties: counties ? counties.join(",") : "",
    date_from,
    date_to,
    compare_date_from: compare_date_from || "",
    compare_date_to: compare_date_to || "",
    export_data_type: split_types ? split_types.join(",") : "",
    lfl: String(lfl),
    product: materials ? materials.join(",") : "",
    store_types: segments ? segments.join(",") : "",
    chart_period: timeline_unit,
    vendor_id: hasMultipleCompanies ? companies?.join(",") : vendor_id,
    voivodeships: voivodeships ? voivodeships.join(",") : "",
    brands: brands ? brands.join(",") : "",
    sub_brands: sub_brands ? sub_brands.join(",") : ""
  };

  return params;
};
