import qs, { ParsedQuery } from "query-string";
import { createSelector } from "reselect";

import { VOIVODESHIPS } from "components/D3/Voivodeship/constants";
import {
  CountyItem,
  CountyStoreInstance,
  getSelectedCounties
} from "pages/Reports/redux/reducers/sweetStateHooks/useCounty";
import { AppState } from "store";
import { RESET_DATA, resetData } from "store/actions/appActions";
import {
  countiesQueryParamSelector,
  reportExportParamsByPeriodSelector,
  urlParamSelector
} from "store/selectors/routerSelectors";
import { HTTP, REST_API_ENDPOINTS } from "utils";
import { Thunk, Values } from "utils/types";

//TYPES
type LocationReportState = {
  isFetching: boolean;
  urls: {
    [query: string]: string;
  };
};

type SetLocationReportFetchingAction = {
  type: typeof SET_LOCATION_REPORT_FETCHING;
  payload: boolean;
};

type UpdateReportsURLsAction = {
  type: typeof UPDATE_REPORTS_URLS;
  payload: { [query: string]: string };
};

type Actions = {
  SET_LOCATION_REPORT_FETCHING: SetLocationReportFetchingAction;
  UPDATE_REPORTS_URLS: UpdateReportsURLsAction;
};

//CONST
const SET_LOCATION_REPORT_FETCHING = "Location.SET_LOCATION_REPORTS_FETCHING" as const;
const UPDATE_REPORTS_URLS = "Location.UPDATE_REPORTS_URLS" as const;

//ACTIONS
const setLocationReportFetching = (
  payload: boolean
): SetLocationReportFetchingAction => ({
  type: SET_LOCATION_REPORT_FETCHING,
  payload
});

const updateReportsUrls = (payload: {
  [query: string]: string;
}): UpdateReportsURLsAction => ({
  type: UPDATE_REPORTS_URLS,
  payload
});

// SELECTORS
const locationReportSelector = (state: AppState) =>
  state.reports.location.report || initialState;

export const isLocationReportFetchingSelector = createSelector(
  locationReportSelector,
  ({ isFetching }) => isFetching
);

const locationReportsUrlsSelector = createSelector(
  locationReportSelector,
  ({ urls }) => urls
);

const locationExportParamsByPeriodSelector = createSelector(
  urlParamSelector,
  reportExportParamsByPeriodSelector,
  countiesQueryParamSelector,
  (urlParam, reportExportParamsByPeriod, countiesQueryParam) => (
    period: string,
    allCounties: CountyItem[]
  ) => {
    return getLocationReportExportParams(
      urlParam,
      reportExportParamsByPeriod(period),
      countiesQueryParam,
      allCounties
    );
  }
);

const locationExportQueryByPeriodSelector = (
  allCounties: CountyItem[],
  period: string
) =>
  createSelector(
    locationExportParamsByPeriodSelector,
    locationExportParamsByPeriod =>
      qs.stringify(locationExportParamsByPeriod(period, allCounties), {
        arrayFormat: "comma"
      })
  );

const locationReportUrlByPeriodSelector = (
  allCounties: CountyItem[],
  period: string
) =>
  createSelector(
    locationReportsUrlsSelector,
    locationExportQueryByPeriodSelector(allCounties, period),
    (locationReportsUrls, locationExportQueryByPeriod) =>
      locationReportsUrls[locationExportQueryByPeriod] || ""
  );

// THUNKS
export const generateLocationReport: (
  period: string
) => Thunk<
  SetLocationReportFetchingAction | UpdateReportsURLsAction
> = period => async (dispatch, getState) => {
  const state = getState();
  const isFetching = isLocationReportFetchingSelector(state);
  if (isFetching) return;
  const countyStore = CountyStoreInstance.storeState.getState();
  const selectedCounties = getSelectedCounties(countyStore);

  const url = locationReportUrlByPeriodSelector(
    selectedCounties,
    period
  )(state);
  if (url) return;

  const locationParams = locationExportParamsByPeriodSelector(state)(
    period,
    selectedCounties
  );
  const query = locationExportQueryByPeriodSelector(
    selectedCounties,
    period
  )(state);

  dispatch(setLocationReportFetching(true));
  try {
    const response = await HTTP.get(
      REST_API_ENDPOINTS.REPORTS.SALES_BY_LOCATION_EXPORT,
      { params: locationParams }
    );

    if (!response?.data?.url) {
      throw new Error(
        "Invalid API response: Location report url was not provided."
      );
    }

    dispatch(setLocationReportFetching(false));
    dispatch(
      updateReportsUrls({
        [query]: response.data.url
      })
    );

    window.location.assign(response.data.url);
  } catch (err) {
    dispatch(setLocationReportFetching(false));
    console.error(err.message);
  }
};

// UTILS
export const getLocationReportExportParams = (
  urlParam: string,
  params: ParsedQuery,
  countiesQueryParam: string | string[],
  allCounties: CountyItem[]
): ParsedQuery => {
  const isDetailedView = Object.values(VOIVODESHIPS).some(
    voivodeship => voivodeship === urlParam
  );

  if (!isDetailedView) return params;

  const selectedCounties = Array.isArray(countiesQueryParam)
    ? countiesQueryParam
    : [countiesQueryParam];
  const countiesInVoivodeship = allCounties.filter(
    ({ voivodeship }) => voivodeship === urlParam
  );

  const updatedCountiesQueryParam = selectedCounties.reduce<string[]>(
    (counties, county) => {
      const isinVoivodeship = countiesInVoivodeship.some(
        ({ value }) => value === county
      );

      if (isinVoivodeship) {
        return [...counties, county.replace("-", " ")];
      }
      return counties;
    },
    []
  );

  return {
    ...params,
    voivodeships: urlParam,
    counties: updatedCountiesQueryParam
  };
};

// REDUCER
const initialState: LocationReportState = {
  isFetching: false,
  urls: {}
};

export const locationReportReducer = (
  state = initialState,
  action: Values<Actions> | ReturnType<typeof resetData>
): LocationReportState => {
  switch (action.type) {
    case SET_LOCATION_REPORT_FETCHING:
      return { ...state, isFetching: action.payload };
    case UPDATE_REPORTS_URLS:
      return {
        ...state,
        urls: {
          ...state.urls,
          ...action.payload
        }
      };

    case RESET_DATA:
      return initialState;
    default:
      return state;
  }
};
