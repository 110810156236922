import { ParsedQuery } from "query-string";

import { User } from "store/reducers/userReducer";
import { toArray } from "store/utils";
import { QP } from "utils/defaultQueryParams";

export const adjustVendorIdParam = (
  params: ParsedQuery<string>,
  user: User,
  isPowerUser: boolean,
  isCompaniesDropdownMultiChoice: boolean
): ParsedQuery<string | string[]> => {
  const userVendorId = user.company?.warehouseId;
  const hasUserOwnVendorId: boolean = !isPowerUser && Boolean(userVendorId);

  // apply warehouse id assigned to the non-power users
  if (hasUserOwnVendorId) {
    params[QP.VENDOR_ID] = userVendorId;
    return params;
  }

  //if there is no params, leave it as is
  if (!params[QP.VENDOR_ID]) return params;

  // select first vendor id in case company dropdown isn't multi choice and there is many vendors
  const vendorIds = toArray(params[QP.VENDOR_ID]);
  const isMultipleVendorsSelected =
    !isCompaniesDropdownMultiChoice && vendorIds.length > 1;

  if (isMultipleVendorsSelected) {
    params[QP.VENDOR_ID] = vendorIds[0];
  }

  return params;
};
