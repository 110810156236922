import React from "react";
import { useDispatch, useSelector } from "react-redux";

import qs from "query-string";

import {
  asyncFiltersSelector,
  setAsyncFilterFetchingError as setError,
  setAsyncFilterFetchingFlag as setFlag,
  updateAsyncFilterQuery as updateQuery
} from "pages/Reports/redux/reducers/asyncFiltersReducer";
import {
  allCompetitionCompaniesSelector,
  checkCompetitorCompaniesPristineSelectionsAfterFetching,
  fetchCompetitorCompanies
} from "pages/Reports/redux/reducers/filters/competition/competitionCompaniesFilterReducer";
import { useFetchingCallback } from "pages/Reports/redux/reducers/filters/hooks/useFetchingCallback";
import { useFiltersFetchingParams } from "pages/Reports/redux/reducers/filters/hooks/useFiltersFetchingParams";

type CompetitorCompanyRequestParams = {
  categories: string;
  category_level: string;
  vendor_id: string;
  attributes?: string;
};

const useFilterParams = () => {
  const params = useFiltersFetchingParams([
    "category",
    "categoryLevel",
    "attributes",
    "vendor"
  ]);

  const base: CompetitorCompanyRequestParams = {
    categories: params.category,
    category_level: params.categoryLevel,
    vendor_id: params.vendor
  };

  if (params.attributes) {
    Object.assign(base, { attributes: params.attributes });
  }

  return base;
};

const useFetchingFlag = (
  params: {
    categories: string;
    category_level: string;
  },
  isDisabled: boolean
) => {
  const isLoading = useSelector(asyncFiltersSelector).fetching
    .competitorCompanies;
  const isError = useSelector(asyncFiltersSelector).error.competitorCompanies;
  const lastQuery = useSelector(asyncFiltersSelector).query.competitorCompanies;
  const query = qs.stringify(params);

  if (isLoading || isError || isDisabled) {
    return;
  }

  if (query === lastQuery) {
    return;
  }

  if (!params.categories || !params.category_level) {
    return;
  }

  return true;
};

const useQueryParamsSelections = () => {
  const dispatch = useDispatch();
  const isPristine = useSelector(asyncFiltersSelector).isFormPristine;
  const companies = useSelector(allCompetitionCompaniesSelector);

  React.useEffect(() => {
    if (isPristine) {
      dispatch(checkCompetitorCompaniesPristineSelectionsAfterFetching());
    }
  }, [dispatch, isPristine, companies]);
};

export const useCompetitorCompanyFetching = (isDisabled: boolean) => {
  const filter = "competitorCompanies";
  const dispatch = useDispatch();

  const params = useFilterParams();
  const isFetchingPossible = useFetchingFlag(params, isDisabled);
  const callback = useFetchingCallback(filter);

  React.useEffect(() => {
    if (!isFetchingPossible) {
      return;
    }

    const fetch = async () => {
      try {
        dispatch(updateQuery({ filter, query: qs.stringify(params) }));

        dispatch(setFlag({ filter, isFetching: true }));
        const companies = await fetchCompetitorCompanies(params);
        dispatch(setError({ filter, status: "" }));

        return companies;
      } catch (e) {
        dispatch(setError({ filter, status: "Błąd pobierania dostawców" }));
        return [];
      } finally {
        dispatch(setFlag({ filter, isFetching: false }));
      }
    };

    fetch().then(callback);
  }, [dispatch, isFetchingPossible, params, callback]);
};

export const useCompetitionCompanyBehaviour = (isDisabled: boolean) => {
  useCompetitorCompanyFetching(isDisabled);
  useQueryParamsSelections();
};
