import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { TooltipContent } from "api/types";

import {
  arePromotionsDisabledSelector,
  fetchedPromotionsSelector
} from "pages/Reports/redux/selectors/promotionsSelectors";
import { REPORTS_FULL_PATHS } from "pages/Reports/utils";
import { toggleChartSidebar } from "store/actions/appActions";
import {
  pathnameSelector,
  promotionChartDataTypeSelector
} from "store/selectors/routerSelectors";
import { isThisPage, PROMOTIONS_FOR_DAY_PERIOD_ONLY } from "utils";
import { Nullable } from "utils/types";

export const useSidebarPromotions = (data: Nullable<TooltipContent>) => {
  const pathname = useSelector(pathnameSelector);
  const arePromotionsDisabled = useSelector(arePromotionsDisabledSelector);
  const dataType = useSelector(promotionChartDataTypeSelector);
  const promotions = useSelector(fetchedPromotionsSelector);

  const isSegments = isThisPage(pathname, REPORTS_FULL_PATHS.SEGMENTS_PATH);
  const isDisabled = isSegments ? false : arePromotionsDisabled; // [PMD-3186]: don't disable promotions tab on segments

  if (arePromotionsDisabled) {
    return {
      isDisabled,
      info: [PROMOTIONS_FOR_DAY_PERIOD_ONLY]
    };
  }

  const isPromotionSelected = dataType.length > 0;
  const noPromotionsInData = data?.promotions?.length === 0;
  let info = [];

  if (isPromotionSelected && noPromotionsInData) {
    info.push(
      "W tym dniu nie było promocji dot. wybranych produktów lub kategorii"
    );
  }

  if (!isPromotionSelected && promotions.length > 0) {
    info.push(
      "Wybierz przynajmniej jeden typ promocji, aby sprawdzić czy w danym okresie były prowadzone akcje promocyjne"
    );
  }

  if (promotions.length === 0) {
    info.push("W wybranym przez Ciebie okresie nie było promocji.");
  }

  return {
    isDisabled,
    info
  };
};

export const useSegmentsSidebarOpenStatus = () => {
  const dispatch = useDispatch();
  const [isInitialMount, setInitialMount] = useState(true);
  const pathname = useSelector(pathnameSelector);
  const promotions = useSelector(promotionChartDataTypeSelector);

  const isSegmentsPage = isThisPage(pathname, REPORTS_FULL_PATHS.SEGMENTS_PATH);
  const hasSelectedPromotions = promotions.length > 0;

  useEffect(() => {
    if (!isSegmentsPage) return;

    if (isInitialMount && !hasSelectedPromotions) {
      dispatch(toggleChartSidebar(false));
    }

    if (hasSelectedPromotions) {
      dispatch(toggleChartSidebar(true));
    }

    setInitialMount(false);
  }, [dispatch, hasSelectedPromotions, isInitialMount, isSegmentsPage]);
};
