import { REPORTS_PATHS } from "pages/Reports/utils";
import { APP_PATHS, USER_ROLES } from "utils";
import { isLogisticsSegmentsEnabled } from "utils/constants";

export const FILTER_NAMES = {
  CATEGORY_1: "category1",
  CATEGORY_2: "category2",
  CATEGORY_3: "category3",
  COMPANY: "company",
  COMPETITORS: "competitors",
  DELAY: "delay",
  COMPETITOR_COMPANY: "competitorCompany",
  COMPETITOR_PRODUCT: "competitorProduct",
  PRODUCT: "product",
  BRAND: "brand",
  SUB_BRAND: "subBrand",
  COMPETITOR_BRAND: "competitorBrand",
  COMPETITOR_SUB_BRAND: "competitorSubBrand",
  REFERENCE_BRAND: "referenceBrand",
  REFERENCE_SUB_BRAND: "referenceSubBrand",
  VOIVODESHIP: "voivodeship",
  COUNTY: "county",
  STORE_TYPE_META: "storeTypeMeta",
  STORE_TYPE: "storeType",
  CLIENT_TYPE: "clientType",
  LFL: "lfl",
  AGGREGATION: "aggregation",
  COMPETITOR_AGGREGATION: "competitorAggregation",
  DATE: "date",
  TIME_AGGREGATION: "timeAggregation",
  EXPORT_DATA_TYPE: "exportDataType",
  REFERENCE_CATEGORY: "referenceCategory",
  REFERENCE_CATEGORY_AGGREGATION: "referenceCategoryAggregation",
  PROMOTIONS: "promotions",
  ATTRIBUTES: "attributes"
} as const;

const { ANALYST, ADMIN, CATMAN, SUPERUSER, LOGISTIC } = USER_ROLES;
const { INSIGHTS, EXPORT } = APP_PATHS;
const {
  SELL,
  SHARES,
  LOCATION,
  DYNAMICS,
  RECEIPTS,
  LOGISTICS,
  SEGMENTS,
  HOURS,
  LOYALTY,
  MULTIPACK,
  PROMOTIONS
} = REPORTS_PATHS;

const ALL_REPORTS = [
  SELL,
  SHARES,
  LOCATION,
  DYNAMICS,
  RECEIPTS,
  LOGISTICS,
  SEGMENTS,
  EXPORT,
  HOURS,
  LOYALTY,
  MULTIPACK,
  PROMOTIONS
];

export const FILTERS = [
  {
    name: FILTER_NAMES.CATEGORY_1,
    roles: [ANALYST, ADMIN, CATMAN, SUPERUSER, LOGISTIC],
    accessLevels: [1, 2, 3, 4],
    page: [...ALL_REPORTS, INSIGHTS]
  },
  {
    name: FILTER_NAMES.CATEGORY_2,
    roles: [ANALYST, ADMIN, CATMAN, SUPERUSER, LOGISTIC],
    accessLevels: [1, 2, 3, 4],
    page: [...ALL_REPORTS, INSIGHTS]
  },
  {
    name: FILTER_NAMES.CATEGORY_3,
    roles: [ANALYST, ADMIN, CATMAN, SUPERUSER, LOGISTIC],
    accessLevels: [1, 2, 3, 4],
    page: [...ALL_REPORTS, INSIGHTS]
  },
  {
    name: FILTER_NAMES.ATTRIBUTES,
    roles: [ANALYST, ADMIN, CATMAN, SUPERUSER],
    accessLevels: [3],
    page: [SELL, SHARES, DYNAMICS, RECEIPTS, SEGMENTS]
  },
  {
    name: FILTER_NAMES.COMPANY,
    roles: [CATMAN, SUPERUSER],
    accessLevels: [1, 2, 3, 4],
    page: [...ALL_REPORTS, INSIGHTS]
  },
  {
    name: FILTER_NAMES.PRODUCT,
    roles: [ANALYST, ADMIN, CATMAN, SUPERUSER, LOGISTIC],
    accessLevels: [1, 2, 3, 4],
    page: [...ALL_REPORTS, INSIGHTS]
  },
  {
    name: FILTER_NAMES.BRAND,
    roles: [ANALYST, ADMIN, CATMAN, SUPERUSER, LOGISTIC],
    accessLevels: [1, 2, 3, 4],
    page: [
      SELL,
      SHARES,
      DYNAMICS,
      RECEIPTS,
      SEGMENTS,
      HOURS,
      LOGISTICS,
      EXPORT,
      INSIGHTS,
      LOYALTY,
      MULTIPACK,
      PROMOTIONS
    ]
  },
  {
    name: FILTER_NAMES.SUB_BRAND,
    roles: [ANALYST, ADMIN, CATMAN, SUPERUSER, LOGISTIC],
    accessLevels: [1, 2, 3, 4],
    page: [
      SELL,
      SHARES,
      DYNAMICS,
      RECEIPTS,
      SEGMENTS,
      HOURS,
      LOGISTICS,
      EXPORT,
      INSIGHTS,
      LOYALTY,
      MULTIPACK,
      PROMOTIONS
    ]
  },
  {
    name: FILTER_NAMES.PROMOTIONS,
    roles: [ANALYST, ADMIN, CATMAN, SUPERUSER],
    accessLevels: [3, 4],
    page: [PROMOTIONS]
  },
  {
    name: FILTER_NAMES.DELAY,
    roles: [CATMAN, SUPERUSER],
    accessLevels: [3, 4],
    page: [
      SELL,
      SHARES,
      LOCATION,
      DYNAMICS,
      RECEIPTS,
      SEGMENTS,
      HOURS,
      LOYALTY,
      MULTIPACK
    ]
  },
  {
    name: FILTER_NAMES.COMPETITOR_BRAND,
    roles: [ANALYST, ADMIN, CATMAN, SUPERUSER],
    accessLevels: [3, 4],
    page: [
      SELL,
      SHARES,
      DYNAMICS,
      RECEIPTS,
      SEGMENTS,
      HOURS,
      LOYALTY,
      MULTIPACK,
      PROMOTIONS
    ]
  },
  {
    name: FILTER_NAMES.COMPETITOR_SUB_BRAND,
    roles: [ANALYST, ADMIN, CATMAN, SUPERUSER],
    accessLevels: [3, 4],
    page: [
      SELL,
      SHARES,
      DYNAMICS,
      RECEIPTS,
      SEGMENTS,
      HOURS,
      LOYALTY,
      MULTIPACK,
      PROMOTIONS
    ]
  },
  {
    name: FILTER_NAMES.COMPETITOR_COMPANY,
    roles: [ANALYST, ADMIN, CATMAN, SUPERUSER],
    accessLevels: [3, 4],
    page: [
      SELL,
      SHARES,
      LOCATION,
      DYNAMICS,
      RECEIPTS,
      SEGMENTS,
      HOURS,
      LOYALTY,
      MULTIPACK,
      PROMOTIONS
    ]
  },
  {
    name: FILTER_NAMES.COMPETITOR_PRODUCT,
    roles: [ANALYST, ADMIN, CATMAN, SUPERUSER],
    accessLevels: [3, 4],
    page: [
      SELL,
      SHARES,
      LOCATION,
      DYNAMICS,
      RECEIPTS,
      SEGMENTS,
      HOURS,
      LOYALTY,
      MULTIPACK,
      PROMOTIONS
    ]
  },
  {
    name: FILTER_NAMES.VOIVODESHIP,
    roles: [ANALYST, ADMIN, CATMAN, SUPERUSER],
    accessLevels: [3, 4],
    page: [
      SELL,
      SHARES,
      LOCATION,
      DYNAMICS,
      RECEIPTS,
      SEGMENTS,
      EXPORT,
      HOURS,
      LOYALTY,
      MULTIPACK
    ]
  },
  {
    name: FILTER_NAMES.COUNTY,
    roles: [ANALYST, ADMIN, CATMAN, SUPERUSER],
    accessLevels: [3, 4],
    page: [
      SELL,
      SHARES,
      LOCATION,
      DYNAMICS,
      RECEIPTS,
      SEGMENTS,
      EXPORT,
      HOURS,
      LOYALTY,
      MULTIPACK
    ]
  },
  {
    name: FILTER_NAMES.STORE_TYPE,
    roles: [ANALYST, ADMIN, CATMAN, SUPERUSER],
    accessLevels: [1, 2, 3, 4],
    page: [
      SELL,
      SHARES,
      LOCATION,
      DYNAMICS,
      RECEIPTS,
      SEGMENTS,
      EXPORT,
      HOURS,
      MULTIPACK,
      ...(isLogisticsSegmentsEnabled ? [LOGISTICS] : [])
    ]
  },
  {
    name: FILTER_NAMES.STORE_TYPE_META,
    roles: [ANALYST, ADMIN, CATMAN, SUPERUSER],
    accessLevels: [1, 2, 3, 4],
    page: [
      SELL,
      SHARES,
      LOCATION,
      DYNAMICS,
      RECEIPTS,
      SEGMENTS,
      EXPORT,
      HOURS,
      MULTIPACK,
      ...(isLogisticsSegmentsEnabled ? [LOGISTICS] : [])
    ]
  },
  {
    name: FILTER_NAMES.CLIENT_TYPE,
    roles: [ANALYST, ADMIN, CATMAN, SUPERUSER],
    accessLevels: [3, 4],
    page: [
      SELL,
      MULTIPACK,
      LOCATION,
      SHARES,
      DYNAMICS,
      RECEIPTS,
      SEGMENTS,
      HOURS,
      EXPORT
    ]
  },
  {
    name: FILTER_NAMES.LFL,
    roles: [ANALYST, ADMIN, CATMAN, SUPERUSER],
    accessLevels: [3, 4],
    page: [SELL, SHARES, LOCATION, DYNAMICS, SEGMENTS, EXPORT, HOURS, MULTIPACK]
  },
  {
    name: FILTER_NAMES.AGGREGATION,
    roles: [ANALYST, ADMIN, CATMAN, SUPERUSER],
    accessLevels: [1, 2, 3, 4],
    page: [
      SELL,
      SHARES,
      LOCATION,
      DYNAMICS,
      RECEIPTS,
      SEGMENTS,
      EXPORT,
      HOURS,
      LOYALTY,
      MULTIPACK
    ]
  },
  {
    name: FILTER_NAMES.COMPETITOR_AGGREGATION,
    roles: [ANALYST, ADMIN, CATMAN, SUPERUSER],
    accessLevels: [2],
    page: [SELL, LOYALTY]
  },
  {
    name: FILTER_NAMES.DATE,
    roles: [ANALYST, ADMIN, CATMAN, SUPERUSER, LOGISTIC],
    accessLevels: [1, 2, 3, 4],
    page: [...ALL_REPORTS, INSIGHTS]
  },
  {
    name: FILTER_NAMES.TIME_AGGREGATION,
    roles: [ANALYST, ADMIN, CATMAN, SUPERUSER],
    accessLevels: [1, 2, 3, 4],
    page: [
      SELL,
      SHARES,
      LOCATION,
      DYNAMICS,
      RECEIPTS,
      SEGMENTS,
      EXPORT,
      HOURS,
      LOYALTY,
      MULTIPACK
    ]
  },
  {
    name: FILTER_NAMES.EXPORT_DATA_TYPE,
    roles: [ANALYST, ADMIN, CATMAN, SUPERUSER],
    accessLevels: [1, 2, 3, 4],
    page: [EXPORT]
  },
  {
    name: FILTER_NAMES.REFERENCE_CATEGORY,
    roles: [ANALYST, ADMIN, CATMAN, SUPERUSER],
    accessLevels: [2, 3, 4],
    page: [
      SELL,
      SHARES,
      DYNAMICS,
      RECEIPTS,
      SEGMENTS,
      EXPORT,
      HOURS,
      LOYALTY,
      MULTIPACK
    ]
  },
  {
    name: FILTER_NAMES.REFERENCE_BRAND,
    roles: [SUPERUSER, CATMAN, ADMIN, ANALYST],
    accessLevels: [3],
    page: [SELL]
  },
  {
    name: FILTER_NAMES.REFERENCE_SUB_BRAND,
    roles: [SUPERUSER, CATMAN, ADMIN, ANALYST],
    accessLevels: [3],
    page: [SELL]
  },
  {
    name: FILTER_NAMES.REFERENCE_CATEGORY_AGGREGATION,
    roles: [ANALYST, ADMIN, CATMAN, SUPERUSER],
    accessLevels: [2, 3, 4],
    page: [
      SELL,
      SHARES,
      DYNAMICS,
      RECEIPTS,
      SEGMENTS,
      EXPORT,
      HOURS,
      LOYALTY,
      MULTIPACK
    ]
  }
];
