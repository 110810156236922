import memoize from "memoize-one";
import { createSelector } from "reselect";

import { reportsSelector } from "pages/Reports/redux/selectors/reportsSelectors";
import { reportExportQueryByPeriodSelector } from "store/selectors/routerSelectors";

const dynamicsReportSelector = createSelector(
  reportsSelector,
  reports => reports.v2Dynamics.report
);

export const dynamicsReportLoadingSelector = createSelector(
  dynamicsReportSelector,
  report => report.isFetching
);

const dynamicsReportsUrlsSelector = createSelector(
  dynamicsReportSelector,
  ({ urls }) => urls
);

export const dynamicsReportUrlByPeriodSelector = createSelector(
  dynamicsReportsUrlsSelector,
  reportExportQueryByPeriodSelector,
  (dynamicsReportsUrls, reportExportQueryByPeriod) =>
    memoize(
      (period: string) =>
        dynamicsReportsUrls[reportExportQueryByPeriod(period)] || ""
    )
);
