import qs from "query-string";
import { values } from "ramda";

import { AppState } from "store";
import { RESET_DATA, resetData } from "store/actions/appActions";
import { history } from "store/utils";
import { Thunk, Values } from "utils/types";

// TYPES
export type ExportDataTypeState = Values<typeof EXPORT_DATA_TYPE>;

type UpdateExportDataTypeAction = {
  type: typeof UPDATE_EXPORT_DATA_TYPE;
  payload: ExportDataTypeState;
};

type Actions = {
  UPDATE_EXPORT_DATA_TYPE: UpdateExportDataTypeAction;
};

// CONST
export const EXPORT_DATA_TYPE = {
  GENERAL: "general",
  SEGMENTS: "segments",
  VOIVODESHIPS: "voivodeships",
  COUNTIES: "counties",
  SHOPS: "shops",
  HOURS: "hour"
} as const;

const UPDATE_EXPORT_DATA_TYPE = "EXPORT_DATA.UPDATE_EXPORT_DATA_TYPE" as const;

// UTILS
const getInitialExportDataTypeState = (): ExportDataTypeState => {
  const { export_data_type = EXPORT_DATA_TYPE.GENERAL } = qs.parse(
    history.location.search
  );
  const isValid = values(EXPORT_DATA_TYPE).includes(
    export_data_type as ExportDataTypeState
  );

  if (!isValid) {
    return EXPORT_DATA_TYPE.GENERAL;
  }

  return export_data_type as ExportDataTypeState;
};

// ACTIONS
export const updateExportDataType = (payload: ExportDataTypeState) => ({
  type: UPDATE_EXPORT_DATA_TYPE,
  payload
});

// THUNKS
export const clearExportDataTypeFilter = (): Thunk<UpdateExportDataTypeAction> => dispatch => {
  dispatch(updateExportDataType(EXPORT_DATA_TYPE.GENERAL));
};

// SELECTORS
export const exportDataTypeSelector = (state: AppState): ExportDataTypeState =>
  state.reports.filters.exportDataType;

// REDUCER
const initialState: ExportDataTypeState = getInitialExportDataTypeState();

export const exportDataTypeReducer = (
  state = initialState,
  action: Values<Actions> | ReturnType<typeof resetData>
) => {
  switch (action.type) {
    case UPDATE_EXPORT_DATA_TYPE:
      return action.payload;

    case RESET_DATA:
      return EXPORT_DATA_TYPE.GENERAL;

    default:
      return state;
  }
};
