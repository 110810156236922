import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";

import { StateChangeOptions } from "downshift";

import {
  Dropdown,
  FilterDropdownInput,
  FilterDropdownListItem,
  FilterDropdownMenu
} from "components/molecules";
import { useSelectedFilters } from "pages/Reports/redux/reducers/filters/filtersReducer";
import { FILTER_NAMES } from "pages/Reports/redux/reducers/filters/hooks/constants";
import { useVisibleList } from "pages/Reports/redux/reducers/filters/hooks/useVisibleFilters";
import {
  AggregatedHooks,
  AggregationTypes,
  AggregationTypesItem
} from "pages/Reports/redux/reducers/sweetStateHooks/useAggregated";
import {
  createDataAggregationTooltip,
  translateDataAggregation
} from "pages/Reports/sections/Export/utils/translateParams";
import { queryParamsSelector } from "store/selectors/routerSelectors";
import { isAttributesEnabled } from "utils/constants";
import { QP } from "utils/defaultQueryParams";

import { DROPDOWN_LABELS, DROPDOWN_PLACEHOLDERS } from "../constants";
import { filterItems, isActiveFn } from "../utils";
import s from "./aggregationFilters.module.scss";

interface Props {
  isDisabled: boolean;
}

const isAttributeType = (type: AggregationTypes) => {
  return [AggregationTypes.attr_group, AggregationTypes.attr_total].includes(
    type
  );
};

const useAttributesState = () => {
  const visibleFilters = useVisibleList();
  const selected = useSelectedFilters();
  const qp = useSelector(queryParamsSelector);

  if (!isAttributesEnabled) {
    return { isVisible: false, isLast: false };
  }

  const attributes = selected.attributes || String(qp[QP.ATTRIBUTES] || "");
  const brands = selected.brands || String(qp[QP.BRANDS] || "");
  const subBrands = selected.sub_brands || String(qp[QP.SUB_BRANDS] || "");
  const product = selected.product || String(qp[QP.PRODUCT] || "");
  const comp = {
    vendors:
      selected.competing_companies || String(qp[QP.COMPETING_COMPANIES] || ""),
    products:
      selected.competing_materials || String(qp[QP.COMPETING_MATERIALS] || ""),
    brands: selected.competing_brands || String(qp[QP.COMPETING_BRANDS] || ""),
    subBrands:
      selected.competing_sub_brands || String(qp[QP.COMPETING_SUB_BRANDS] || "")
  };

  const isVisible = Boolean(
    visibleFilters.find(filter => filter.name === FILTER_NAMES.ATTRIBUTES)
  );
  const isLast =
    attributes.length > 0 &&
    comp.vendors.length > 0 &&
    [
      brands,
      subBrands,
      product,
      comp.products,
      comp.brands,
      comp.subBrands
    ].every(filter => !filter.length);

  return { isVisible, isLast };
};

export const AggregationDropdown = ({ isDisabled }: Props) => {
  const [isOpen, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [state, actions] = AggregatedHooks.useAggregated();
  const attributes = useAttributesState();

  const handleInputValueChange = (value?: string) => {
    if (!value && value !== "") {
      return;
    }

    setInputValue(value);
  };

  const handleOpen = () => {
    setOpen(true);
    setInputValue("");
  };

  const handleClose = (item: AggregationTypes) => {
    setOpen(false);

    if (!item) {
      setInputValue(translateDataAggregation(state.selected));
    }
  };

  const handleStateChange = (
    changes: StateChangeOptions<AggregationTypesItem[]>
  ) => {
    // must be explicit condition, isOpen is possibly undefined
    if (changes.isOpen === true) {
      handleOpen();
    }

    if (changes.isOpen === false) {
      // @ts-ignore type mismatch due to improper definitions of downshift
      handleClose(changes.selectedItem || AggregationTypes.false);
    }
  };

  const handleChange = (item: AggregationTypesItem) => {
    setOpen(false);
    actions.updateAggregated(item.value);
  };

  const items = useMemo(() => {
    return Object.values(AggregationTypes)
      .filter(type => {
        if (attributes.isVisible) return true;
        return !isAttributeType(type);
      })
      .map(type => ({
        label: translateDataAggregation(type),
        value: type,
        tooltipText: createDataAggregationTooltip(type),
        isDisabled: isAttributeType(type) ? !attributes.isLast : false
      }));
  }, [attributes.isLast, attributes.isVisible]);

  const selectedItem = {
    label: translateDataAggregation(state.selected),
    value: state.selected
  };

  useEffect(() => {
    if (!isOpen) setInputValue(translateDataAggregation(state.selected));
  }, [isOpen, state.selected]);

  // [PMD-4794]: fix attributes aggregation if attributes filter is not visible or not last
  useEffect(() => {
    if (
      isAttributeType(state.selected) &&
      (!attributes.isVisible || !attributes.isLast)
    ) {
      actions.updateAggregated(AggregationTypes.false);
    }
  }, [actions, attributes.isLast, attributes.isVisible, state.selected]);

  return (
    <Dropdown<AggregationTypesItem[]>
      className={s.timeAggregationDropdownWrapper}
      isDisabled={isDisabled}
      // @ts-ignore workoaround: downshift calls onInputValueCahnge  with result of itemToString prop call.
      // once we know this fn always returns undefined it makes downshift fully controled and inputValue
      // depends only on state of CategoryDropdown
      itemToString={() => {}}
      inputValue={inputValue}
      onInputValueChange={handleInputValueChange}
      isOpen={isOpen}
      label={DROPDOWN_LABELS.DATA_AGGREGATION}
      items={items}
      selectedItem={[selectedItem]}
      // @ts-ignore
      onChange={handleChange}
      onStateChange={handleStateChange}
      openedMenuPlaceholder={DROPDOWN_PLACEHOLDERS.DATA_AGGREGATION.OPEN}
      closedMenuPlaceholder={DROPDOWN_PLACEHOLDERS.DATA_AGGREGATION.CLOSED}
      input={
        <FilterDropdownInput
          isTypingEnabled
          automaticInputValue={translateDataAggregation(state.selected)}
          testId="data-aggregation-dropdown"
        />
      }
      menu={
        <FilterDropdownMenu
          isItemActiveFn={isActiveFn}
          filterItemsFn={filterItems}
          listItem={
            <FilterDropdownListItem
              className={{
                base: s.aggregationDropdownBase,
                itemText: s.aggregationDropdownItemText
              }}
            />
          }
        />
      }
    />
  );
};
