import { ThunkAction } from "redux-thunk";

import {
  dynamicsReportLoadingSelector,
  dynamicsReportUrlByPeriodSelector
} from "pages/Reports/sections/Dynamics/redux/selectors/dynamicsReportSelectors";
import { AppState } from "store";
import {
  reportExportParamsByPeriodSelector,
  reportExportQueryByPeriodSelector
} from "store/selectors/routerSelectors";
import { HTTP, REST_API_ENDPOINTS } from "utils";
import { Values } from "utils/types";

// TYPES
interface State {
  isFetching: boolean;
  urls: {
    [query: string]: string;
  };
}

interface SetDynamicsReportLoadingAction {
  type: typeof SET_DYNAMICS_REPORT_LOADING;
  payload: boolean;
}

interface UpdateDynamicsReportsUrlsActions {
  type: typeof UPDATE_DYNAMICS_REPORTS_URLS;
  payload: { [query: string]: string };
}

interface DynamicsReportExportApi {
  url: string;
}

type Actions = {
  SET_DYNAMICS_REPORT_LOADING: SetDynamicsReportLoadingAction;
  UPDATE_DYNAMICS_REPORTS_URLS: UpdateDynamicsReportsUrlsActions;
};

// ACTIONS
const SET_DYNAMICS_REPORT_LOADING = "Dynamics.SET_DYNAMICS_REPORT_LOADING";
const UPDATE_DYNAMICS_REPORTS_URLS = "Dynamics.UPDATE_DYNAMICS_REPORTS_URLS";

const setDynamicsReportLoading = (payload: boolean) => ({
  type: SET_DYNAMICS_REPORT_LOADING,
  payload
});

const updateDynamicsReportsUrls = (payload: { [query: string]: string }) => ({
  type: UPDATE_DYNAMICS_REPORTS_URLS,
  payload
});

// THUNKS
export const generateDynamicsReport: (
  period: string
) => ThunkAction<void, AppState, undefined, any> = period => async (
  dispatch,
  getState
) => {
  const state = getState();
  const isFetching = dynamicsReportLoadingSelector(state);
  if (isFetching) return;

  const url = dynamicsReportUrlByPeriodSelector(state)(period);
  if (url) return;

  const query = reportExportQueryByPeriodSelector(state)(period);
  const params = reportExportParamsByPeriodSelector(state)(period);

  dispatch(setDynamicsReportLoading(true));
  try {
    const response = await HTTP.get<DynamicsReportExportApi>(
      REST_API_ENDPOINTS.REPORTS.DYNAMICS_EXPORT,
      {
        params
      }
    );

    if (!response?.data?.url) {
      throw new Error(
        "Invalid API response: Dynamics report url was not provided."
      );
    }

    dispatch(setDynamicsReportLoading(false));
    dispatch(
      updateDynamicsReportsUrls({
        [query]: response.data.url
      })
    );

    window.location.assign(response.data.url);
  } catch (err) {
    dispatch(setDynamicsReportLoading(false));
    console.error(err.message);
  }
};

// REDUCER
const initialState: State = {
  isFetching: false,
  urls: {}
};

export const dynamicsReportReducer = (
  state: State = initialState,
  action: Values<Actions>
): State => {
  switch (action.type) {
    case SET_DYNAMICS_REPORT_LOADING: {
      return {
        ...state,
        isFetching: action.payload
      };
    }
    case UPDATE_DYNAMICS_REPORTS_URLS:
      return {
        ...state,
        urls: {
          ...state.urls,
          ...action.payload
        }
      };
    default:
      return state;
  }
};
