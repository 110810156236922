import { useFiltersQP } from "hooks";
import {
  BOOL_STRING_VALUES,
  CLIENT_TYPES,
  FILTER_SECTIONS
} from "utils/constants";

const {
  MAIN,
  COMPETITION,
  REFERENCE_CATEGORIES,
  REFERENCE_BRANDS,
  REGION,
  SHOP_TYPE,
  CLIENT_TYPE,
  LFL,
  EXPORT_DATA_TYPE
} = FILTER_SECTIONS;

export const useIntitialFiltersSectionOpen = (sectionKey: string): boolean => {
  const {
    refCat1QP,
    refCat2QP,
    refCat3QP,
    refBrandQP,
    refSubBrandQP,
    shopTypeQP,
    shopTypeMetaQP,
    voivodeshipsQP,
    countiesQP,
    competitionCompaniesQP,
    competitionProductsQP,
    clientTypesQP,
    lflQP,
    exportDataTypeQP
  } = useFiltersQP();

  switch (sectionKey) {
    case MAIN.key:
      return true;
    case COMPETITION.key:
      return !!competitionCompaniesQP || !!competitionProductsQP;
    case REFERENCE_CATEGORIES.key:
      return !!refCat1QP || !!refCat2QP || !!refCat3QP;
    case REFERENCE_BRANDS.key:
      return !!refBrandQP || !!refSubBrandQP;
    case REGION.key:
      return !!voivodeshipsQP || !!countiesQP;
    case SHOP_TYPE.key:
      return !!shopTypeQP || !!shopTypeMetaQP;
    case CLIENT_TYPE.key:
      return !!clientTypesQP && clientTypesQP !== CLIENT_TYPES.ALL;
    case LFL.key:
      return lflQP === BOOL_STRING_VALUES.TRUE;
    case EXPORT_DATA_TYPE.key:
      return !!exportDataTypeQP;
    default:
      return false;
  }
};
