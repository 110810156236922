import { ThunkAction } from "redux-thunk";

import {
  receiptsReportLoadingSelector,
  receiptsReportUrlByPeriodSelector
} from "pages/Reports/sections/Receipts/redux/selectors/receiptsReportSelectors";
import { AppState } from "store";
import {
  reportExportParamsByPeriodSelector,
  reportExportQueryByPeriodSelector
} from "store/selectors/routerSelectors";
import { HTTP, REST_API_ENDPOINTS } from "utils";
import { Values } from "utils/types";

// TYPES
interface State {
  isFetching: boolean;
  urls: {
    [query: string]: string;
  };
}

interface SetReceiptsReportLoadingAction {
  type: typeof SET_RECEIPTS_REPORT_LOADING;
  payload: boolean;
}

interface UpdateReceiptsReportsUrlsActions {
  type: typeof UPDATE_RECEIPTS_REPORTS_URLS;
  payload: { [query: string]: string };
}

interface ReceiptsReportExportApi {
  url: string;
}

type Actions = {
  SET_RECEIPTS_REPORT_LOADING: SetReceiptsReportLoadingAction;
  UPDATE_RECEIPTS_REPORTS_URLS: UpdateReceiptsReportsUrlsActions;
};

// ACTIONS
const SET_RECEIPTS_REPORT_LOADING = "Receipts.SET_RECEIPTS_REPORT_LOADING";
const UPDATE_RECEIPTS_REPORTS_URLS = "Receipts.UPDATE_RECEIPTS_REPORTS_URLS";

const setReceiptsReportLoading = (payload: boolean) => ({
  type: SET_RECEIPTS_REPORT_LOADING,
  payload
});

const updateReceiptsReportsUrls = (payload: { [query: string]: string }) => ({
  type: UPDATE_RECEIPTS_REPORTS_URLS,
  payload
});

// THUNKS
export const generateReceiptsReport: (
  period: string
) => ThunkAction<void, AppState, undefined, any> = period => async (
  dispatch,
  getState
) => {
  const state = getState();
  const isFetching = receiptsReportLoadingSelector(state);
  if (isFetching) return;

  const url = receiptsReportUrlByPeriodSelector(state)(period);
  if (url) return;

  const query = reportExportQueryByPeriodSelector(state)(period);
  const params = reportExportParamsByPeriodSelector(state)(period);

  dispatch(setReceiptsReportLoading(true));
  try {
    const response = await HTTP.get<ReceiptsReportExportApi>(
      REST_API_ENDPOINTS.REPORTS.RECEIPTS_EXPORT,
      {
        params
      }
    );

    if (!response?.data?.url) {
      throw new Error(
        "Invalid API response: Receipts report url was not provided."
      );
    }

    dispatch(setReceiptsReportLoading(false));
    dispatch(
      updateReceiptsReportsUrls({
        [query]: response.data.url
      })
    );

    window.location.assign(response.data.url);
  } catch (err) {
    dispatch(setReceiptsReportLoading(false));
    console.error(err.message);
  }
};

// REDUCER
const initialState: State = {
  isFetching: false,
  urls: {}
};

export const receiptsReportReducer = (
  state: State = initialState,
  action: Values<Actions>
): State => {
  switch (action.type) {
    case SET_RECEIPTS_REPORT_LOADING: {
      return {
        ...state,
        isFetching: action.payload
      };
    }
    case UPDATE_RECEIPTS_REPORTS_URLS:
      return {
        ...state,
        urls: {
          ...state.urls,
          ...action.payload
        }
      };
    default:
      return state;
  }
};
