import { useSelector } from "react-redux";

import {
  FILTER_NAMES,
  FILTERS
} from "pages/Reports/redux/reducers/filters/hooks/constants";
import { REPORTS_FULL_PATHS, REPORTS_PATHS } from "pages/Reports/utils";
import {
  isPowerUserSelector,
  tierSelector,
  userRoleSelector,
  userSelector
} from "store/reducers/userReducer";
import { pathnameSelector } from "store/selectors/routerSelectors";
import { isThisPage, USER_ROLES } from "utils";
import { isReferenceBrandsFilterEnabled } from "utils/constants";
import { RESTRICTIONS } from "utils/restrictions";

const useRestricted = () => {
  const user = useSelector(userSelector);
  const pathname = useSelector(pathnameSelector);
  const restricted = Object.values(RESTRICTIONS).find(restriction => {
    return restriction.ID === user.company?.warehouseId;
  });

  if (!restricted) return [];

  if (isThisPage(pathname, REPORTS_FULL_PATHS.SELL_PATH)) {
    return FILTERS.filter(({ name }) =>
      restricted.FILTERS.SALES.includes(name)
    );
  }

  return [];
};

const usePageVisibility = (
  filters: {
    name: string;
    accessLevels: number[];
    roles: string[];
    page: string[];
  }[]
) => {
  const pathname = useSelector(pathnameSelector);

  return filters.filter(filter =>
    filter.page.some(page => pathname.includes(page))
  );
};

const useRoleVisibility = (
  filters: { name: string; accessLevels: number[]; roles: string[] }[]
) => {
  const userRole = useSelector(userRoleSelector);

  return filters.filter(filter => filter.roles.includes(userRole));
};

const useTierVisibility = (
  filters: { name: string; accessLevels: number[] }[]
) => {
  const tier = useSelector(tierSelector);
  const role = useSelector(userRoleSelector);

  const generalFilters = filters.filter(filter =>
    filter.accessLevels.includes(tier)
  );

  const rolesToCheck = [USER_ROLES.ADMIN, USER_ROLES.ANALYST] as string[];
  if (tier === 4 && rolesToCheck.includes(role)) {
    const competitorFilters: string[] = [
      FILTER_NAMES.COMPETITOR_COMPANY,
      FILTER_NAMES.COMPETITOR_BRAND,
      FILTER_NAMES.COMPETITOR_SUB_BRAND,
      FILTER_NAMES.COMPETITOR_PRODUCT,
      FILTER_NAMES.COMPETITOR_AGGREGATION
    ];
    return generalFilters.filter(
      filter => !competitorFilters.includes(filter.name)
    );
  }
  return generalFilters;
};

const useShopTypeVisibility = (
  filters: { name: string; accessLevels: number[] }[]
) => {
  const pathname = useSelector(pathnameSelector);
  const isLocationPage = pathname.includes(REPORTS_PATHS.LOCATION);
  const isPowerUser = useSelector(isPowerUserSelector);

  const hiddenFilters: string[] = [
    FILTER_NAMES.STORE_TYPE,
    FILTER_NAMES.STORE_TYPE_META
  ];

  if (isLocationPage) {
    return isPowerUser
      ? filters
      : filters.filter(filter => !hiddenFilters.includes(filter.name));
  }
  return filters;
};

const useReferenceBrandsFiltersVisibilty = (
  filters: { name: string; accessLevels: number[] }[]
) => {
  if (isReferenceBrandsFilterEnabled) {
    return filters;
  }

  return filters.filter(filter => filter.name !== FILTER_NAMES.REFERENCE_BRAND);
};

export const useVisibleList = (): { name: string }[] => {
  const restricted = useRestricted();
  const pageFilters = usePageVisibility(FILTERS);
  const roleFilters = useRoleVisibility(pageFilters);
  const tierFilters = useTierVisibility(roleFilters);
  const shopTypeFilters = useShopTypeVisibility(tierFilters);
  const referenceBrandsFilters = useReferenceBrandsFiltersVisibilty(
    shopTypeFilters
  );

  return restricted.length > 0 ? restricted : referenceBrandsFilters;
};
