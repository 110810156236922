import { useMemo } from "react";
import { useRouteMatch } from "react-router-dom";

import { generateDynamicsReport } from "pages/Reports/sections/Dynamics/redux/reducers/dynamicsReportReducer";
import { generateLocationReport } from "pages/Reports/sections/Location/redux/reducers/locationReportReducer";
import { generateReceiptsReport } from "pages/Reports/sections/Receipts/redux/reducers/receiptsReportReducer";
import { generateSellsReport } from "pages/Reports/sections/Sell/redux/actions/reportActions";
import { generateSharesReport } from "pages/Reports/sections/Shares/redux/reducers/sharesReportReducer";
import { REPORTS_FULL_PATHS } from "pages/Reports/utils";

const {
  LOCATION_PATH,
  SHARES_PATH,
  DYNAMICS_PATH,
  RECEIPTS_PATH
} = REPORTS_FULL_PATHS;

type EmptyAction = () => { type: "" };
type DownloadAction =
  | ReturnType<typeof generateLocationReport>
  | ReturnType<typeof generateSellsReport>
  | ReturnType<typeof generateSharesReport>
  | ReturnType<typeof generateDynamicsReport>
  | EmptyAction;

export const useDownloadReportAction = (period: string) => {
  const locationMatch = useRouteMatch(LOCATION_PATH);
  const isLocationPage = Boolean(locationMatch);

  const sharesMatch = useRouteMatch(SHARES_PATH);
  const isSharesPage = Boolean(sharesMatch);

  const dynamicsMatch = useRouteMatch(DYNAMICS_PATH);
  const isDynamicsPage = Boolean(dynamicsMatch);

  const receiptsMatch = useRouteMatch(RECEIPTS_PATH);
  const isReceiptsPage = Boolean(receiptsMatch);

  return useMemo<DownloadAction>(() => {
    if (isLocationPage) {
      return generateLocationReport(period);
    }

    if (isSharesPage) {
      return generateSharesReport(period);
    }

    if (isDynamicsPage) {
      return generateDynamicsReport(period);
    }

    if (isReceiptsPage) {
      return generateReceiptsReport(period);
    }

    return generateSellsReport(period);
  }, [isLocationPage, isSharesPage, isDynamicsPage, isReceiptsPage, period]);
};
