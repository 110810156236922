import { valueByDataType } from "pages/Reports/utils";

export const sortBarChartData = (key: string, desc: boolean) => (
  a: any,
  b: any
) => {
  const valueA = valueByDataType(key, a.timeline[0]);
  const valueB = valueByDataType(key, b.timeline[0]);
  return desc ? valueB - valueA : valueA - valueB;
};
