import { useState } from "react";
import { useQuery } from "react-query";

import { HTTP, REST_API_ENDPOINTS } from "utils";

import { useFiltersFetchingParams } from "./useFiltersFetchingParams";

type AttributesHierarchyApi = {
  columns: Record<string, string>;
  rows: Record<string, string>[];
};

const STALE_TIME = 1000 * 60 * 60 * 10; // 10 hours

const useParams = () => {
  const params = useFiltersFetchingParams([
    "category",
    "categoryLevel",
    "tier"
  ]);
  return {
    categories: params.category,
    category_level: params.categoryLevel,
    tier: params.tier
  };
};

export const useAttributesHierarchy = () => {
  const params = useParams();
  const query = useQuery(
    ["attributes-hierarchy", params],
    async () => {
      const response = await HTTP.get<AttributesHierarchyApi>(
        REST_API_ENDPOINTS.HIERARCHY_ATTRIBUTES,
        { params }
      );
      return response.data;
    },
    { staleTime: STALE_TIME }
  );

  return {
    isFetching: query.isFetching,
    data: query.data || { columns: {}, rows: [] }
  };
};

export const useAttributesHierarchyFile = () => {
  const params = useParams();
  const [isFetching, setFetching] = useState(false);

  const download = async () => {
    setFetching(true);
    try {
      const resposne = await HTTP.get<{ url: string }>(
        REST_API_ENDPOINTS.HIERARCHY_ATTRIBUTES_EXPORT,
        { params }
      );
      window.location.assign(resposne.data.url);
    } catch (err) {
      console.error("Error while downloading attributes hierarchy file.");
    } finally {
      setFetching(false);
    }
  };

  return { isFetching, download };
};
