import { EXPORT_DATA_TYPE } from "pages/Reports/redux/reducers/filters/exportDataTypeReducer";
import { useSelectedFilters } from "pages/Reports/redux/reducers/filters/filtersReducer";
import {
  CategoryStoreInstance,
  getCategoryWithLevel
} from "pages/Reports/redux/reducers/sweetStateHooks/useCategory";
import {
  getSelectedReferenceCategoryWithLevel,
  ReferenceCategoryInstance
} from "pages/Reports/redux/reducers/sweetStateHooks/useReferenceCategory";
import { isCompaniesDropdownMultiChoiceSelector } from "pages/Reports/redux/selectors/reportsSelectors";
import {
  mapExportBodyForBE,
  normalizeExportTableData
} from "pages/Reports/sections/Export/utils/mapBEValues";
import { REPORTS_PATHS } from "pages/Reports/utils";
import { setSuccessMessage } from "store/actions/appActions";
import { pathnameSelector } from "store/selectors/routerSelectors";
import { SettingSuccessMessageAction } from "store/types/appTypes";
import { HTTP, REST_API_ENDPOINTS } from "utils";
import { pushReportFetchEvent } from "utils/googleTagManager/dataLayer";
import { Thunk, Values } from "utils/types";

import { ExportTableDataApiSplit, ExportTableDataSplit } from "../types";
import { exportGenerationStatusSelector } from "./exportSelectors";

// TYPES
export enum RequestStatus {
  IDLE = "IDLE",
  IN_PROGRESS = "IN_PROGRESS",
  FAIL = "FAIL",
  SUCCESS = "SUCCESS"
}

interface SetExportTableDataAction {
  type: typeof SET_EXPORT_TABLE_DATA;
  payload: ExportTableDataSplit;
}

interface SetExportFetchingStatusAction {
  type: typeof SET_EXPORT_FETCHING_STATUS;
  payload: RequestStatus;
}

interface SetExportGenerationStatusAction {
  type: typeof SET_EXPORT_GENERATION_STATUS;
  payload: RequestStatus;
}

interface ExportState {
  fetchingStatus: RequestStatus;
  generationStatus: RequestStatus;
  tableData: ExportTableDataSplit;
}

type ExportActions = Values<{
  SET_EXPORT_TABLE_DATA: SetExportTableDataAction;
  SET_EXPORT_FETCHING_STATUS: SetExportFetchingStatusAction;
  SET_EXPORT_GENERATING_STATUS: SetExportGenerationStatusAction;
}>;

// CONST
const SET_EXPORT_TABLE_DATA = "Export.Raw.SET_EXPORT_TABLE_DATA";
const SET_EXPORT_FETCHING_STATUS = "Export.Raw.SET_EXPORT_FETCHING_STATUS";
const SET_EXPORT_GENERATION_STATUS = "Export.Raw.SET_EXPORT_GENERATION_STATUS";

const exportInitialState: ExportState = {
  fetchingStatus: RequestStatus.IN_PROGRESS,
  generationStatus: RequestStatus.IDLE,
  tableData: { archived: [], unarchived: [] }
};

// ACTIONS
const setExportTableData = (
  payload: ExportTableDataSplit
): SetExportTableDataAction => ({
  type: SET_EXPORT_TABLE_DATA,
  payload
});

const setExportFetchingStatus = (
  payload: RequestStatus
): SetExportFetchingStatusAction => ({
  type: SET_EXPORT_FETCHING_STATUS,
  payload
});

export const setExportGenerationStatus = (
  payload: RequestStatus
): SetExportGenerationStatusAction => ({
  type: SET_EXPORT_GENERATION_STATUS,
  payload
});

// THUNKS
export const getExportTableData = (): Thunk<ExportActions> => async dispatch => {
  dispatch(setExportFetchingStatus(RequestStatus.IN_PROGRESS));

  try {
    const { data } = await HTTP.get<ExportTableDataApiSplit>(
      REST_API_ENDPOINTS.REPORTS.SUPER_EXPORT
    );

    dispatch(setExportTableData(normalizeExportTableData(data)));
    dispatch(setExportFetchingStatus(RequestStatus.SUCCESS));

    return true;
  } catch (error) {
    dispatch(setExportFetchingStatus(RequestStatus.FAIL));
    error instanceof Error && console.error(error.message);

    return false;
  }
};

export const generateExportData = (
  query: ReturnType<typeof useSelectedFilters>
): Thunk<ExportActions> => async (dispatch, getState) => {
  const state = getState();
  const status = exportGenerationStatusSelector(state);
  const pathname = pathnameSelector(state);
  const isSegmentsPage = pathname.includes(REPORTS_PATHS.SEGMENTS);
  const isHoursPage = pathname.includes(REPORTS_PATHS.HOURS);

  if (status === RequestStatus.IN_PROGRESS) return;

  const categoryStore = CategoryStoreInstance.storeState.getState();
  const categories = getCategoryWithLevel(categoryStore);
  const {
    date_from,
    date_to,
    compare_date_from,
    compare_date_to,
    include_leap_year: include_leap_year_day,
    product: materials,
    vendor_id,
    aggregated: aggregation,
    lfl,
    voivodeships,
    counties,
    store_types: segments,
    client: client_type,
    export_data_type: split_types,
    chart_period: timeline_unit,
    brands,
    sub_brands,
    excluded_days
  } = query;

  const referenceCategoryStore = ReferenceCategoryInstance.storeState.getState();
  const [
    reference_categories,
    reference_category_level
  ] = getSelectedReferenceCategoryWithLevel(referenceCategoryStore);

  const isMultiChoice = isCompaniesDropdownMultiChoiceSelector(state);
  const companies: string[] =
    isMultiChoice && vendor_id.length > 1 ? vendor_id : [];

  let splitTypes = split_types;
  if (isSegmentsPage) splitTypes = EXPORT_DATA_TYPE.SEGMENTS;
  if (isHoursPage) splitTypes = EXPORT_DATA_TYPE.HOURS;

  const params = mapExportBodyForBE({
    date_from,
    date_to,
    compare_date_from,
    compare_date_to,
    include_leap_year_day,
    categories: categories[0],
    category_level: categories[1],
    timeline_unit,
    materials,
    vendor_id,
    companies,
    aggregation,
    lfl,
    voivodeships,
    counties,
    segments,
    split_types: splitTypes,
    client_type,
    reference_categories,
    reference_category_level,
    brands,
    sub_brands,
    excluded_days
  });
  const hasRequiredValues = [
    params.date_from,
    params.date_to,
    params.categories?.length,
    params.category_level,
    params.timeline_unit
  ].every(value => Boolean(value));

  if (!hasRequiredValues) return;

  dispatch(setExportGenerationStatus(RequestStatus.IN_PROGRESS));

  try {
    await HTTP.post<unknown>(
      REST_API_ENDPOINTS.REPORTS.GENERATE_SUPER_EXPORT,
      params
    );
    pushReportFetchEvent("Eksport danych");
    dispatch(setExportGenerationStatus(RequestStatus.SUCCESS));
  } catch (error) {
    error instanceof Error && console.error(error.message);
    dispatch(setExportGenerationStatus(RequestStatus.FAIL));
  }
};

export const archiveExport = (
  exportId: string
): Thunk<ExportActions | SettingSuccessMessageAction> => async dispatch => {
  const params = {
    is_archived: true
  };

  try {
    await HTTP.put<unknown>(
      `${REST_API_ENDPOINTS.REPORTS.UPDATE_SUPER_EXPORT}${exportId}/`,
      params
    );

    dispatch(setSuccessMessage("Pomyślnie zarchiwizowano plik."));

    dispatch(getExportTableData());
  } catch (error) {
    console.error(error);
  }
};

// REDUCER
export const exportReducer = (
  state: ExportState = exportInitialState,
  action: ExportActions
): ExportState => {
  switch (action.type) {
    case SET_EXPORT_TABLE_DATA:
      return { ...state, tableData: action.payload };

    case SET_EXPORT_FETCHING_STATUS:
      return { ...state, fetchingStatus: action.payload };

    case SET_EXPORT_GENERATION_STATUS:
      return { ...state, generationStatus: action.payload };

    default:
      return state;
  }
};
