import cn from "classnames";

import { CHART_FONT_COLORS } from "utils/constants";

import s from "./chartValueTiles.module.scss";

export interface SingleTileData {
  backgroundColor: string;
  firstValue: string;
  secondValue?: string;
  onClick: () => void;
  opacity: string;
}

interface Props {
  tile: SingleTileData;
  className?: {
    base?: string;
  };
}

export const ChartValueSingleTile = ({ tile, className }: Props) => {
  if (!tile) return null;

  const color = CHART_FONT_COLORS[tile.backgroundColor];

  return (
    <div
      style={{
        backgroundColor: tile.backgroundColor,
        color,
        opacity: tile.opacity
      }}
      className={cn(s.tileBox, className?.base)}
      onClick={tile.onClick}
    >
      <p>{tile.firstValue}</p>
      {tile.secondValue && <p>({tile.secondValue})</p>}
    </div>
  );
};
