import memoize from "memoize-one";
import { createSelector } from "reselect";

import { AppState } from "store";
import { reportExportQueryByPeriodSelector } from "store/selectors/routerSelectors";

const reportsSelector = (state: AppState) => state.reports;

const sellReportSelector = createSelector(
  reportsSelector,
  reports => reports.sell.report
);

export const sellReportLoadingSelector = createSelector(
  sellReportSelector,
  report => report.isFetching
);

const sellReportsUrlsSelector = createSelector(
  sellReportSelector,
  ({ urls }) => urls
);

export const sellReportUrlByPeriodSelector = createSelector(
  sellReportsUrlsSelector,
  reportExportQueryByPeriodSelector,
  (sellReportsUrls, reportExportQueryByPeriod) =>
    memoize(
      (period: string) =>
        sellReportsUrls[reportExportQueryByPeriod(period)] || ""
    )
);
