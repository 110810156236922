import { stringify } from "query-string";

import {
  arePromotionsDisabledSelector,
  shouldFetchNewPromotionsSelector
} from "pages/Reports/redux/selectors/promotionsSelectors";
import { availablePromotionDataTypesSelector } from "pages/Reports/redux/selectors/promotionsSelectors";
import { transformPromotionApiData } from "pages/Reports/redux/utils";
import { PromotionChartDataType } from "pages/Reports/types/chart";
import { generatePromotionsParams } from "pages/Reports/utils";
import { RESET_DATA, resetData } from "store/actions/appActions";
import { updateQueryParams } from "store/actions/routerActions";
import {
  pathnameSelector,
  searchStringSelector,
  uppercasedPromotionChartDataTypeSelector
} from "store/selectors/routerSelectors";
import { CHART_DATA_TYPE, HTTP, REST_API_ENDPOINTS } from "utils";
import { QP } from "utils/defaultQueryParams";
import { Nullable, Thunk, Values } from "utils/types";

//CONST
const UPDATE_PROMOTIONS = "REPORTS.UPDATE_PROMOTIONS" as const;
const UPDATE_PROMOTIONS_ERROR = "REPORTS.UPDATE_PROMOTIONS_ERROR" as const;
const UPDATE_PROMOTIONS_SUCCESS = "REPORTS.UPDATE_PROMOTIONS_SUCCESS" as const;

// TYPES
export type MaterialData = {
  material?: number;
  display: string;
};

export type PromotionDataType = FetchedPromotion & {
  firstDayOfGivenPeriod: string;
  lastDayOfGivenPeriod: string;
  datesDifference: number;
};

export enum PromotionPeriod {
  BASE = "BASE",
  HISTORICAL = "HISTORICAL"
}

export type PromotionTableData = {
  promotionName: string;
  promotionDesc: string;
  ownMaterials: Material[];
  competingMaterials: Material[];
  period: PromotionPeriod;
};

export type Material = {
  vendor_name: string;
  data: MaterialData[];
};

type MaterialsApi = {
  competing_materials: Material[];
  own_materials: Material[];
};

type Materials = {
  competingMaterials: Material[];
  ownMaterials: Material[];
};

export interface PromotionsApi {
  valid_from: string;
  valid_to: string;
  variant: string;
  display: string;
  materials: MaterialsApi;
  promotion_group: string;
  promotion_desc: Nullable<string>;
  category_display: string;
}

export type FetchedPromotion = {
  validTo: string;
  validFrom: string;
  variant: string;
  display: PromotionChartDataType;
  materials: Materials;
  promotionGroup: string;
  promotionDesc: string;
  categoryDisplay: string;
  period: PromotionPeriod;
};

type PromotionPayload = {
  fetchedPromotions: FetchedPromotion[];
  fetchedHistoricalPromotions: FetchedPromotion[];
  query: string;
};

interface IPromotionsState extends PromotionPayload {
  isFetching: boolean;
}

type UpdatePromotionsAction = {
  type: typeof UPDATE_PROMOTIONS;
};

type UpdatePromotionsActionSuccess = {
  type: typeof UPDATE_PROMOTIONS_SUCCESS;
  payload: PromotionPayload;
};

type UpdatePromotionsActionError = {
  type: typeof UPDATE_PROMOTIONS_ERROR;
  payload: string;
};

type PromotionsActions = {
  UPDATE_PROMOTIONS: UpdatePromotionsAction;
  UPDATE_PROMOTIONS_ERROR: UpdatePromotionsActionError;
  UPDATE_PROMOTIONS_SUCCESS: UpdatePromotionsActionSuccess;
};

// ACTIONS
const updatePromotions = (): UpdatePromotionsAction => ({
  type: UPDATE_PROMOTIONS
});

const updatePromotionsSuccess = (
  payload: PromotionPayload
): UpdatePromotionsActionSuccess => ({
  type: UPDATE_PROMOTIONS_SUCCESS,
  payload
});

const updatePromotionsError = (
  payload: string
): UpdatePromotionsActionError => ({
  type: UPDATE_PROMOTIONS_ERROR,
  payload
});

// THUNKS
export const selectAllPromotionTypes = (): Thunk<UpdatePromotionsAction> => (
  dispatch,
  getState
) => {
  const state = getState();
  const availablePromotionDataTypes = availablePromotionDataTypesSelector(
    state
  );

  const stringifiedAllPromotionTypes = availablePromotionDataTypes.map(type =>
    type.toLowerCase()
  );

  return dispatch(
    updateQueryParams({
      [QP.PROMOTIONS_CHART_DATA_TYPE]: stringifiedAllPromotionTypes
    })
  );
};

export const clearAllPromotionTypes = (): Thunk<UpdatePromotionsAction> => dispatch => {
  return dispatch(
    updateQueryParams({ [QP.PROMOTIONS_CHART_DATA_TYPE]: CHART_DATA_TYPE.NONE })
  );
};

const createPromotionRequest = (params: { [key: string]: string }) =>
  HTTP.get<PromotionsApi[]>(REST_API_ENDPOINTS.REPORTS.PROMOTIONS, {
    params
  });

export const getPromotionsData = (): Thunk<
  | UpdatePromotionsActionSuccess
  | UpdatePromotionsActionError
  | UpdatePromotionsAction
> => async (dispatch, getState) => {
  const state = getState();
  const pathname = pathnameSelector(state);
  const search = searchStringSelector(state);
  const newPromotionsParams = generatePromotionsParams(pathname, search);
  const newPromotionQueryString = stringify(newPromotionsParams);
  const shouldFetchNewPromos = shouldFetchNewPromotionsSelector(state);
  const arePromotionsDisabled = arePromotionsDisabledSelector(state);
  const promotionChartDataType = uppercasedPromotionChartDataTypeSelector(
    state
  );

  if (shouldFetchNewPromos && !arePromotionsDisabled) {
    try {
      dispatch(updatePromotions());

      const getRegularPromotions = createPromotionRequest(newPromotionsParams);

      const getHistoricalPromotions = newPromotionsParams.compare_date_from
        ? createPromotionRequest({
            ...newPromotionsParams,
            date_from: newPromotionsParams.compare_date_from,
            date_to: newPromotionsParams.compare_date_to
          })
        : undefined;

      const [regularPromotions, historicalPromotions] = await Promise.all([
        getRegularPromotions,
        getHistoricalPromotions
      ]);

      const fetchedPromotions = transformPromotionApiData(
        regularPromotions?.data || [],
        PromotionPeriod.BASE
      );
      const fetchedHistoricalPromotions = historicalPromotions
        ? transformPromotionApiData(
            historicalPromotions?.data || [],
            PromotionPeriod.HISTORICAL
          )
        : [];

      //filter out all previously selected promotion types that dont show up in response
      const availablePromotionTypes = fetchedPromotions.map(
        promo => promo.display
      );
      const filteredSelectedPromotionTypes = promotionChartDataType.filter(
        type => availablePromotionTypes.includes(type)
      );
      const stringifiedPromotionTypes = filteredSelectedPromotionTypes.map(
        type => type.toLowerCase()
      );
      dispatch(
        updateQueryParams({
          [QP.PROMOTIONS_CHART_DATA_TYPE]: stringifiedPromotionTypes
        })
      );

      dispatch(
        updatePromotionsSuccess({
          fetchedPromotions,
          fetchedHistoricalPromotions,
          query: newPromotionQueryString
        })
      );
    } catch (err) {
      let message = "Wystąpił błąd podczas pobierania promocji";

      if (err instanceof Error) {
        message = err.message;
      }

      dispatch(updatePromotionsError(message));
    }
  }

  return;
};

//REDUCER
const initialState: IPromotionsState = {
  isFetching: false,
  fetchedPromotions: [],
  fetchedHistoricalPromotions: [],
  query: ""
};

export const promotionsReducer = (
  state = initialState,
  action: Values<PromotionsActions> | ReturnType<typeof resetData>
) => {
  switch (action.type) {
    case UPDATE_PROMOTIONS:
      return {
        ...state,
        isFetching: true
      };
    case UPDATE_PROMOTIONS_SUCCESS:
      return {
        ...state,
        ...action.payload,
        isFetching: false
      };
    case UPDATE_PROMOTIONS_ERROR:
      return {
        ...state,
        isFetching: false,
        fetchedPromotions: [],
        query: ""
      };

    case RESET_DATA:
      return initialState;

    default:
      return state;
  }
};
