import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { StateChangeOptions } from "downshift";
import qs from "query-string";

import { SpinnerLoader, Text } from "components/atoms";
import { Search } from "components/atoms/Icon";
import {
  Dropdown,
  FilterDropdownInput,
  FilterDropdownListItem,
  FilterDropdownMenu
} from "components/molecules";
import { DropdownItem } from "components/molecules/types";
import s from "pages/Reports/partials/ReportsSidebar/ReportsFilterForm/CategoryDropdown/categoryDropdown.module.scss";
import {
  DROPDOWN_LABELS,
  DROPDOWN_PLACEHOLDERS
} from "pages/Reports/partials/ReportsSidebar/ReportsFilterForm/constants";
import { FilterErrorMsg } from "pages/Reports/partials/ReportsSidebar/ReportsFilterForm/FilterErrorMsg/FilterErrorMsg";
import {
  filterItems,
  isActiveFn
} from "pages/Reports/partials/ReportsSidebar/ReportsFilterForm/utils";
import {
  asyncFiltersSelector,
  updateAsyncFilterQuery as updateQuery
} from "pages/Reports/redux/reducers/asyncFiltersReducer";
import { useSupplierBehaviour } from "pages/Reports/redux/reducers/filters/hooks/useSupplierFetching";
import {
  VendorHooks,
  VendorItem
} from "pages/Reports/redux/reducers/sweetStateHooks/useVendor";
import { isCompaniesDropdownMultiChoiceSelector } from "pages/Reports/redux/selectors/reportsSelectors";
import { Nullable } from "utils/types";

type Props = {
  isDisabled: boolean;
  selectedItems: DropdownItem<string>[];
  selectedLabel: string;
};

export const SuperUserCompanies = ({
  isDisabled,
  selectedItems,
  selectedLabel
}: Props) => {
  const [state, actions] = VendorHooks.useVendor();
  const dispatch = useDispatch();
  const [isOpen, setOpen] = React.useState(false);
  const [inputValue, setInputValue] = React.useState("");

  const allItems = state.all;
  const containerRef = React.useRef<HTMLDivElement>(null);

  const isLoading = useSelector(asyncFiltersSelector).fetching.company;
  const errorMsg = useSelector(asyncFiltersSelector).error.company;

  const isMultiChoice = useSelector(isCompaniesDropdownMultiChoiceSelector);

  useSupplierBehaviour(isDisabled);

  useEffect(() => {
    if (!isOpen) setInputValue(selectedLabel);
  }, [isOpen, selectedLabel]);

  const handleInputValueChange = (value?: string) => {
    if (!value && value !== "") {
      return;
    }
    setInputValue(value);
  };

  const handleOpen = () => {
    setOpen(true);
    setInputValue("");
  };

  const handleClose = (selectedItem?: Nullable<VendorItem>) => {
    if (isMultiChoice) {
      setOpen(true);
    } else {
      setOpen(false);
      actions.sortVendors();
    }

    if (!selectedItem) {
      setOpen(false);
      setInputValue(selectedLabel);
      actions.sortVendors();
    }
  };

  const handleStateChange = (changes: StateChangeOptions<VendorItem[]>) => {
    // must be explicit condition, isOpen is possibly undefined
    if (changes.isOpen === true) {
      handleOpen();
    }

    if (changes.isOpen === false) {
      // @ts-ignore type mismatch due to improper definitions of downshift
      handleClose(changes.selectedItem);
    }
  };

  const handleChange = (item: VendorItem | null) => {
    if (isMultiChoice) {
      setOpen(true);
    } else {
      setOpen(false);
    }

    actions.selectVendor(item, isMultiChoice);
    if (isMultiChoice) return;

    return handleClose(item);
  };

  const handleSelectAll = () =>
    actions.selectAllVendors(inputValue, isMultiChoice);

  if (!isLoading && errorMsg) {
    return (
      <FilterErrorMsg
        label={DROPDOWN_LABELS.COMPANY}
        callback={() => {
          dispatch(
            updateQuery({
              filter: "company",
              query: qs.stringify({})
            })
          );
        }}
        errorMsg={errorMsg}
      />
    );
  }

  return (
    <div ref={containerRef}>
      <Dropdown<VendorItem[]>
        className={s.vendorDropdownWrapper}
        isMultiChoice={isMultiChoice}
        selectedItem={selectedItems}
        items={allItems}
        isDisabled={isLoading || isDisabled}
        label={DROPDOWN_LABELS.COMPANY}
        openedMenuPlaceholder={
          isLoading ? "Pobieranie" : DROPDOWN_PLACEHOLDERS.COMPANY.OPEN
        }
        closedMenuPlaceholder={
          isLoading ? "Pobieranie" : DROPDOWN_PLACEHOLDERS.COMPANY.CLOSED
        }
        // @ts-ignore workaround: downshift calls onInputValueCahnge with result of itemToString prop call.
        // once we know this fn always returns undefined it makes downshift fully controled and inputValue
        // depends only on state of SuperUserCompanies
        itemToString={() => {}}
        inputValue={isLoading ? "Pobieranie" : inputValue}
        onInputValueChange={handleInputValueChange}
        isOpen={isOpen}
        // @ts-ignore
        onChange={handleChange}
        onClear={() => handleChange(null)}
        onStateChange={handleStateChange}
        onSelectAll={handleSelectAll}
        input={
          <FilterDropdownInput
            customIcon={isLoading ? <SpinnerLoader size="small" /> : <Search />}
            isTypingEnabled
            automaticInputValue={selectedLabel}
            testId="super-user-companies"
          />
        }
        menu={
          <FilterDropdownMenu<VendorItem>
            isItemActiveFn={(item, items) => {
              return isActiveFn(item, items);
            }}
            filterItemsFn={filterItems}
            inlinePlaceholder={
              isLoading && (
                <Text className={s.placeholder}>pobieranie danych</Text>
              )
            }
            listItem={<FilterDropdownListItem />}
          />
        }
        onOuterClick={() => setOpen(false)}
      />
    </div>
  );
};
